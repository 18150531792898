import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/views/layout/index.vue'
import ClothIndex from '@/views/main/cloths/index.vue'
import CusIndex from '@/views/main/customerCenter/index.vue'
/**
 * 私有路由表
 */
const privateRoutes: Array<RouteRecordRaw> = [
  

]

/**
 * 公开路由表
 */
const publicRoutes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    component: () => import('@/views/login.vue')
  },
  {
    path: '/prelogin',
    component: () => import('@/views/prelogin.vue')
  },
  {
    path: '/',
    redirect: '/main',
  },
  {
    path: '/uploader',
    name: 'uploader',
    component: () => import('@/views/imageUploader.vue'),
    meta: {
      title: 'main.uploader', 
      icon: 'personnel-manage'
    }
  },
  {
    path: '/main',
    component: Layout,
    redirect: '/main/index',
    children: [
      {
        path: '/main/index',
        name: 'index',
        component: () => import('@/views/main/index.vue'),
        meta: {
          title: 'main.index', 
          icon: 'personnel-manage'
        }
      },
      
      {
        path: '/main/cuscenter',
        component: CusIndex,
        redirect: '/main/cuscenter/info',
        children: [
          {
            path: '/main/cuscenter/info',
            name: 'cusinfo',
            component: () => import('@/views/main/customerCenter/userInfo.vue'),
            meta: {
              title: 'main.cuscenter.info', 
              icon: 'personnel-manage'
            }
          },
          {
            path: '/main/cuscenter/favourite',
            name: 'favourite',
            component: () => import('@/views/main/customerCenter/favourite.vue'),
            meta: {
              title: 'main.cuscenter.favourite', 
              icon: 'personnel-manage'
            }
          },
          {
            path: '/main/cuscenter/courseOrders',
            name: 'courseOrders',
            component: () => import('@/views/main/customerCenter/courseOrders.vue'),
            meta: {
              title: 'main.cuscenter.courseOrders', 
              icon: 'personnel-manage'
            }
          },
          {
            path: '/main/cuscenter/orders',
            name: 'orders',
            component: () => import('@/views/main/customerCenter/orders.vue'),
            meta: {
              title: 'main.cuscenter.orders', 
              icon: 'personnel-manage'
            }
          },
          {
            path: '/main/cuscenter/custombokeorderdetails',
            name: 'custombokeorderdetails',
            component: () => import('@/views/main/customerCenter/customBokeOrderDetail.vue'),
            meta: {
              title: 'main.cuscenter.custombokeorderdetails', 
              icon: 'personnel-manage'
            }
          },
          {
            path: '/main/cuscenter/clothaftersales',
            name: 'clothaftersales',
            component: () => import('@/views/main/customerCenter/clothAfterSale.vue'),
            meta: {
              title: 'main.cuscenter.clothaftersales', 
              icon: 'personnel-manage'
            }
          },
          {
            path: '/main/cuscenter/orderconfirm',
            name: 'corderconfirm',
            component: () => import('@/views/main/bokes/orderConfirm.vue'),
            meta: {
              title: 'main.orderconfirm', 
              icon: 'personnel-manage'
            }
          },
          {
            path: '/main/cuscenter/clothorderconfirm',
            name: 'cclothorderconfirm',
            component: () => import('@/views/main/cloths/orderConfirm.vue'),
            meta: {
              title: 'main.orderconfirm', 
              icon: 'personnel-manage'
            }
          },
          
          {
            path: '/main/cuscenter/shippingAddr',
            name: 'shippingAddr',
            component: () => import('@/views/main/customerCenter/address.vue'),
            meta: {
              title: 'main.cuscenter.shippingAddr', 
              icon: 'personnel-manage'
            }
          },
          {
            path: '/main/cuscenter/myfeet',
            name: 'myfeet',
            component: () => import('@/views/main/customerCenter/feets.vue'),
            meta: {
              title: 'main.cuscenter.myfeet', 
              icon: 'personnel-manage'
            }
          },
          {
            path: '/main/cuscenter/scale-orders',
            name: 'scaleOrders',
            component: () => import('@/views/main/customerCenter/autoScaleOrders.vue'),
            meta: {
              title: 'main.cuscenter.scaleOrders', 
              icon: 'personnel-manage'
            }
          },
          {
            path: '/main/cuscenter/my-space',
            name: 'myspace',
            component: () => import('@/views/main/customerCenter/myspace.vue'),
            meta: {
              title: 'main.cuscenter.cloud', 
              icon: 'personnel-manage'
            }
          },
          {
            path: '/main/cuscenter/suggestion',
            name: 'suggestion',
            component: () => import('@/views/main/customerCenter/suggestion.vue'),
            meta: {
              title: 'main.cuscenter.suggestion', 
              icon: 'personnel-manage'
            }
          },
          {
            path: '/main/cuscenter/cloud',
            name: 'cloud',
            component: () => import('@/views/main/customerCenter/cloudDisk.vue'),
            meta: {
              title: 'main.cuscenter.cloud', 
              icon: 'personnel-manage'
            }
          },
          {
            path: '/main/cuscenter/makecloth',
            name: 'cloud',
            component: () => import('@/views/orders/makingCloth.vue'),
            meta: {
              title: 'main.cuscenter.makingCloth', 
              icon: 'personnel-manage'
            }
          },
          
          {
            path: '/main/cuscenter/notes',
            name: 'notes',
            component: () => import('@/views/main/customerCenter/notes.vue'),
            meta: {
              title: 'main.cuscenter.notes', 
              icon: 'personnel-manage'
            },            
          },
          {
            path: '/main/cuscenter/branchs',
            name: 'branchs',
            component: () => import('@/views/main/customerCenter/branchs.vue'),
            meta: {
              title: 'main.cuscenter.branchs', 
              icon: 'personnel-manage'
            }
          },
          {
            path: '/main/cuscenter/myUpgrades',
            name: 'myUpgrades',
            component: () => import('@/views/main/customerCenter/myUpgrades.vue'),
            meta: {
              title: 'main.cuscenter.myUpgrades', 
              icon: 'personnel-manage'
            }
          },
        ]
       },
       {
        path: '/main/buycourse',
        name: 'buycourse',
        component: () => import('@/views/main/buyCourse.vue'),
        meta: {
          title: 'main.buycourse', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/bokes',
        name: 'bokes',
        component: () => import('@/views/main/bokes/index.vue'),
        meta: {
          title: 'main.bokes', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/retails',
        name: 'retails',
        component: () => import('@/views/main/retails/index.vue'),
        meta: {
          title: 'main.retails', 
          icon: 'personnel-manage',          
        }
      },
      {
        path: '/main/retailDetails',
        name: 'retailDetails',
        component: () => import('@/views/main/retails/retailDetails.vue'),
        meta: {
          title: 'main.retailDetails', 
          icon: 'personnel-manage',
          parentPath:'/main/retails'
        }
      },
      {
        path: '/main/autoscale',
        name: 'autoscale',
        component: () => import('@/views/main/autoScale.vue'),
        meta: {
          title: 'main.autoscale', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/bokesdetail',
        name: 'bokesdetail',
        component: () => import('@/views/main/bokes/bokeDetail.vue'),
        meta: {
          title: 'main.bokes', 
          icon: 'personnel-manage',
          parentPath:'/main/bokes'
        }
      },
      {
        path: '/main/bokes/orderconfirm',
        name: 'orderconfirm',
        component: () => import('@/views/main/bokes/orderConfirm.vue'),
        meta: {
          title: 'main.orderconfirm', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/cloth/orderconfirm',
        name: 'clothorderconfirm',
        component: () => import('@/views/main/cloths/orderConfirm.vue'),
        meta: {
          title: 'main.orderconfirm', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/clothmakingorder',
        name: 'clothmakingorder',
        component: () => import('@/views/main/bokes/clothMakingOrder.vue'),
        meta: {
          title: 'main.clothmakingorder', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/bokemakingorder',
        name: 'bokemakingorder',
        component: () => import('@/views/main/bokes/bokeMakingOrder.vue'),
        meta: {
          title: 'main.bokemakingorder', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/buycar',
        name: 'buycar',
        component: () => import('@/views/main/buycar.vue'),
        meta: {
          title: 'main.buycar', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/decorations',
        name: 'decorations',
        component: () => import('@/views/main/decorations/index.vue'),
        meta: {
          title: 'main.decorations', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/decorationDetails',
        name: 'decorationDetails',
        component: () => import('@/views/main/decorations/decorationDetails.vue'),
        meta: {
          title: 'main.decorations', 
          icon: 'personnel-manage',
          parentPath:'/main/decorations'
        }
      },
      {
        path: '/main/accessories',
        name: 'accessories',
        component: () => import('@/views/main/accessories/index.vue'),
        meta: {
          title: 'main.decorations', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/cloths',
        name: 'cloths',
        component:ClothIndex,// () => import('@/views/main/cloths/index1.vue'),
        meta: {
          title: 'main.cloths', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/makingboke',
        name: 'makingboke',
        component: () => import('@/views/main/bokes/makingBoke.vue'),
        meta: {
          title: 'main.makingboke', 
          icon: 'personnel-manage'
        }
      },
      // {
      //   path: '/main/cloths1',
      //   name: 'cloths1',
      //   component: () => import('@/views/main/cloths/index.vue'),
      //   meta: {
      //     title: 'main.cloths', 
      //     icon: 'personnel-manage'
      //   }
      // },
      {
        path: '/main/retailCloth',
        name: 'retailCloth',
        component: () => import('@/views/main/cloths/retailClothDetails.vue'),
        meta: {
          title: 'main.retailCloth', 
          icon: 'personnel-manage'
        }
      },
      
      {
        path: '/main/clothdetail',
        name: 'clothdetail',
        component: () => import('@/views/main/cloths/clothDetails.vue'),
        meta: {
          title: 'main.clothdetail', 
          icon: 'personnel-manage',
          parentPath:'/main/cloths'
        }
      },
      {
        path: '/main/clothsell',
        name: 'clothsell',
        component: () => import('@/views/main/cloths/clothToSell.vue'),
        meta: {
          title: 'main.clothsell', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/fabrics',
        name: 'fabrics',
        component: () => import('@/views/main/fabrics/index.vue'),
        meta: {
          title: 'main.fabrics', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/fabricdetails',
        name: 'fabricdetails',
        component: () => import('@/views/main/fabrics/fabricDetail.vue'),
        meta: {
          title: 'main.fabricdetails', 
          icon: 'personnel-manage',
          parentPath:'/main/fabrics'
        }
      },
      {
        path: '/main/upgradeLevel',
        name: 'upgradeLevel',
        component: () => import('@/views/main/upgradeLevel.vue'),
        meta: {
          title: 'main.upgradeLevel', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/helpcenter',
        name: 'helpcenter',
        component: () => import('@/views/main/helpCenter.vue'),
        meta: {
          title: 'main.helpcenter', 
          icon: 'personnel-manage'
        }
      },
      {
        path: '/main/icon',
        name: 'icon',
        component: () => import('@/views/layout/components/SvgDisplay.vue'),
        meta: {
          title: 'main.helpcenter', 
          icon: 'personnel-manage'
        }
      },
    ]    
  }, 
  // {
  //   path: '/order',
  //   component: Layout,
  //   redirect: '/order/makecustombokeorder',
  //   children: [
  //     {
  //       path: '/order/makecustombokeorder',
  //       name: 'makecustombokeorder',
  //       component: () => import('@/views/orders/bokeMakingOrder.vue'),
  //       meta: {
  //         title: 'main.helpcenter', 
  //         icon: 'personnel-manage'
  //       }
  //     },
  //   ]
  // }
]
  
  // {
  //   path: '/app',
  //   // 注意：带有路径“/”的记录中的组件“默认”是一个不返回 Promise 的函数
  //   component: layout,
  //   redirect: '/app/myapp',
  //   children: [
  //     {
  //       path: '/app/myapp',
  //       name: 'profile',
  //       component: () => import('@/views/profile/index.vue'),
  //       meta: {
  //         title: 'profile',
  //         icon: 'el-icon-user'
  //       }
  //     }
  //   ]
  // }






const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...publicRoutes, ...privateRoutes]
})

export default router
