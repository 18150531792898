import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';
import '@/assets/styles/index.scss'
import installIcons from '@/assets/icons/index'
import i18n from '@/i18n/index'
import './router/permission'
// import { generateNewStyle } from '@/utils/theme'
// import API from 'fghis-ui'
// import 'fghis-ui/dist/css/index.css'
import Loading from '@/components/cLoading'

import CommonUi from '@/components/index'
const app = createApp(App)
// console.log(CommonUi)
installIcons(app)
// API.install(app)
app.use(store).use(router).use(Antd).use(Loading).use(CommonUi).use(i18n).mount('#app')
// generateNewStyle(store.getters.theme)
