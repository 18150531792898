import { setItem, getItem } from '@/utils/storege'
import { TOKEN_VALIDAE_TIME, LOGIN_STAMP } from '@/constants'
export const setTimestamp = (): void => {
  setItem(LOGIN_STAMP, new Date().getTime())
}
export const isTokenTimeout = (): boolean => {
  const token = getItem(LOGIN_STAMP)
  const currentStamp = new Date().getTime()
  return currentStamp - token > TOKEN_VALIDAE_TIME
}
