import request from '@/utils/request'
import store from '@/store'
export default {
  login: (data: any) => {    
    data.orgId=store.getters.orgId
    return request({
      url: 'auth/frontlogin',
      method: 'POST',
      data
    })
  },
  getUserInfo: () => {
    return request({
      url: 'sys/profile',
      method: 'GET',
    })
  },
  getCourses:()=> {
    const orgId=store.getters.orgId
    return request({
      url: `land/courses?orgId=${orgId}`,
      method: 'GET'
      
    }) 
  },
  getCourse:(id)=> {
    return request({
      url: `land/course?id=${id}`,
      method: 'GET'
      
    }) 
  },
  //添加系统信息
  insertSysMessages: (data) => {
    return request({
      url: 'land/insertSysMessages',
      method: 'POST',
      data,
    })
  },
  //查询所有系统信息
  getSysMessages: () => {
    return request({
      url: 'system/sysMessages',
      method: 'GET',
    })
  },
  reserveAudit: (id) => {
    return request({
      url: `fsystem/reserve?orgId=${id}`,
      method: 'GET',
    })
  },
  getOrgByOrgName: (name) => {
    return request({
      url: `fsystem/orgByOrgName?name=${name}`,
      method: 'GET',
    })
  },
  insertSysOperator: (data) => {
    return request({
      url: 'system/addOperator',
      method: 'POST',
      data
    })
  },
  editOperator: (data) => {
    return request({
      url: 'system/editOperator',
      method: 'POST',
      data
    })
  },  
  sysOperatorBySearch: (data) => {
    return request({
      url: 'system/operators',
      method: 'POST',
      data
    })
  },
  
  getRoles: (orgId) => {
    return request({
      url: 'system/roles/'+orgId,
      method: 'GET',
    })
  },
  addOrg:(data) => {
    return request({
      url: 'fsystem/addOrg',
      method: 'POST',
      data
    })
  },
  editOrg:(data) =>{
    return request({
      url: 'fsystem/editOrg',
      method: 'POST',
      data
    })
  },
  addRole:(role)=>{
    return request({
      url: 'system/addRole',
      method: 'POST',
      data:role
    }) 
  },
  setMainOrg:(role)=>{
    return request({
      url: 'system/setMainOrg',
      method: 'POST',
      data:role
    }) 
  },  
  editOrgOther:(data)=>{
    return request({
      url: 'fsystem/addEditOrgOther',
      method: 'POST',
      data:data
    }) 
  },
  getOrgOther:(id)=>{
    return request({
      url: 'system/orgOther/'+id,
      method: 'GET'
    }) 
  },
  deleteRole:(role)=>{
    return request({
      url: 'system/deleteRole',
      method: 'DELETE',
      data:role
    }) 
  },
  deleteRoleFunction:(id)=>{
    return request({
      url: 'system/deleteRoleFunction/'+id,
      method: 'DELETE'
    }) 
  },
  deleteBrand:(id)=>{
    return request({
      url: 'system/deleteBrand/'+id,
      method: 'DELETE'
    }) 
  },
  addBrand:(data) => {
    return request({
      url: 'system/addBrand',
      method: 'POST',
      data
    }) 
  },
  updateSysOperatorDisable: (data) => {
    return request({
      url: 'system/updateSysOperatorDisable',
      method: 'POST',
      data
    })
  },
  getMyOrgs:(id)=>{
    return request({
      url: `fsystem/myOrgs?customerId=${id}`,
      method: 'GET',
    })
  },
  getOrgId:(hostname)=>{
    return request({
      url: `land/orgid?hostname=${hostname}`,
      method: 'GET',
    })
  },
  getWxLoginResult:(state)=>{
    return request({
      url: `land/wxLoginResult?state=${state.t}&orgId=${state.orgId}`,
      method: 'GET',
    })
  },
  sendValideCode:(phone,token)=>{
    const orgId=store.getters.orgId
    return request({
      url: `land/sendValideCode?token=${token}${new Date().getTime()}&phone=${phone}&orgId=${orgId}`,
      method: 'GET',
    })
  },
  sendValideCodeForReg:(phone,loginName,token)=>{
    const orgId=store.getters.orgId
    return request({
      url: `land/sendValideCodeForReg?token=${token}${new Date().getTime()}&loginName=${loginName}&phone=${phone}&orgId=${orgId}`,
      method: 'GET',
    })
  },  
  bindWxLogin:(phone,openId)=>{
    const orgId=store.getters.orgId
    return request({
      url: `land/bindWxLogin?celphone=${phone}&openId=${openId}&orgId=${orgId}`,
      method: 'GET',
    })
  },
  sendValideCodeForBinding:(phone,token)=>{
    const orgId=store.getters.orgId
    return request({
      url: `land/sendValideCodeForBinding?token=${token}${new Date().getTime()}&phone=${phone}&orgId=${orgId}`,
      method: 'GET',
    })
  },
  frontCodeLogin:(phone,code,orgId)=>{
    return request({
      url: `land/frontCodeLogin?code=${code}&phone=${phone}&orgId=${orgId}`,
      method: 'GET',
    })
  },
  getRemindVideos:()=>{
    return request({
      url: 'land/remindVideos',
      method: 'GET',
    })
  },
  getOrgInfo:(id)=>{
    return request({
      url: 'fsystem/org/'+id,
      method: 'GET',
    })
  },
  getOrgInfoByType:(id)=>{
    return request({
      url: 'system/orgs/'+id,
      method: 'GET',
    })
  },
  allBrands:()=>{
    return request({
      url: 'system/allBrands',
      method: 'GET',
    })
  },
  updateOrgInfo: (data) => {
    return request({
      url: 'system/editOrg',
      method: 'POST',
      data
    })
  },
  getAllFunctions:()=>{
    return request({
      url: 'system/allFunctions',
      method: 'GET',
    })
  },
  getStaticData:(type)=>{
    return request({
      url: 'land/statics/'+type,
      method: 'GET',
    })
  },
  getCities:()=>{
    return request({
      url: 'fsystem/cities',
      method: 'GET',
    })
  },
  getNotice:()=>{
    const orgId=store.getters.orgId
    return request({
      url: `land/notices?orgId=${orgId}`,
      method: 'GET',
    })
  },
  addStaticData:(data)=>{
    return request({
      url: 'system/addStaticData',
      method: 'POST',
      data
    })
  },
  getLevels:(orgId)=>{
    return request({
      url: 'land/levels/'+orgId,
      method: 'GET'
    })
  },
  getHomeSettings:()=>{
    const orgId=store.getters.orgId
    return request({
      url: `land/homeSettings?orgId=${orgId}`,
      method: 'GET', 
    })
  },
  getSettings:()=>{
    return request({
      url: 'land/settings',
      method: 'GET'
    })
  },
  deleteStaticData:(data)=>{
    return request({
      url: 'system/editStaticDataInvalid',
      method: 'POST',
      data
    })
  },
  getRoleFunctions:(data)=>{
    return request({
      url: 'system/functions',
      method: 'POST',
      data
    })
    
  },
  addRoleFunction:(data)=>{
    return request({
      url: 'system/addRoleFunction',
      method: 'POST',
      data
    })
    
  },
  saveSizeAttach:(data)=>{
    return request({
      url: 'system/sizeAttach',
      method: 'POST',
      data
    })
    
  },
  getSizeAttach:(id)=>{
    return request({
      url: 'fsystem/sizeAttach/'+id,
      method: 'GET', 
    })
  },
  getUrlHeader:()=>{
    return request({
      url: 'land/urlHeader',
      method: 'GET', 
    })
  },
  getVideos:()=>{
    return request({
      url: 'land/videos',
      method: 'GET', 
    })
  },

  createWXPayOrder:(data)=>{
    return request({
      url: 'merchantPayment/createOrder',
      method: 'POST', 
      data
    })
  },
  
  uploadImage:(data)=>{
    return request({
      url: 'fsystem/uploadOrgImages',
      method: 'POST',
      data,
      headers: {  
        'Content-Type': 'multipart/form-data'  
      }  
    })
    
  },
  uploadSysImages:(data)=>{
    return request({
      url: 'fsystem/uploadSysImages',
      method: 'POST',
      data,
      headers: {  
        'Content-Type': 'multipart/form-data'  
      }  
    })
    
  },
  uploadImageWithName:(data)=>{
    return request({
      url: 'land/uploadImageWithName',
      method: 'POST',
      data,
      headers: {  
        'Content-Type': 'multipart/form-data'  
      }  
    })
    
  },
  uploadImageMessage:(data: any) => {
    return request({
      url: 'land/uploadingImageMessage',
      method: 'POST',
      data
    })
  },
  getUploadImageMessage:(token) => {
    return request({
      url: `land/uploadingImageMessage?token=${token}`,
      method: 'GET'
    })
  },
  uploadBodyImages:(data)=>{
    return request({
      url: 'land/uploadImageFromBase64',
      method: 'POST',
      data,
      headers: {  
        'Content-Type': 'multipart/form-data'  
      }  
    })
    
  },
  
  uploadBokeFile:(data)=>{
    return request({
      url: 'system/uploadBokeFile',
      method: 'POST',
      data,
      headers: {  
        'Content-Type': 'multipart/form-data'  
      }  
    })
    
  },
  uploadSizeFile:(data)=>{
    return request({
      url: 'system/uploadSizeFile',
      method: 'POST',
      data,
      headers: {  
        'Content-Type': 'multipart/form-data'  
      }  
    })
    
  },
  
  getSize:(id)=>{
    return request({
      url: 'system/size/'+id,
      method: 'GET', 
    })
  },
  getSizeGroups:()=>{
    return request({
      url: 'system/sizeGroups',
      method: 'GET', 
    })
  },
  deleteSizeGroup:(id)=>{
    return request({
      url: 'system/deleteSizeGroup/'+id,
      method: 'DELETE', 
    })
  },
  addSizeGroup:(data) => {
    return request({
      url: 'system/addSizeGroup',
      method: 'POST',
      data
    }) 
  },
  addSizeData:(data) => {
    return request({
      url: 'system/addSizeData',
      method: 'POST',
      data
    }) 
  },
  editSizeData:(data) => {
    return request({
      url: 'system/editSizeData',
      method: 'POST',
      data
    }) 
  },
  deleteSizeData:(id)=> {
    return request({
      url: 'system/deleteSizeData/'+id,
      method: 'DELETE',
    }) 
  },
  deleteUnit:(id)=> {
    return request({
      url: 'system/unit/'+id,
      method: 'DELETE',
    }) 
  },
  getUnits:()=> {
    return request({
      url: 'land/units',
      method: 'GET',
    }) 
  },
  saveUnit:(data)=> {
    return request({
      url: 'fsystem/unit',
      method: 'POST',
      data
    }) 
  },
  updatePwd:(data)=> {
    return request({
      url: 'fsystem/updatePwd',
      method: 'POST',
      data
    }) 
  },
  getLeftCount:(id)=> {
    return request({
      url: `fsystem/getLeftCount?id=${id}`,
      method: 'GET',
    }) 
  },
  getProvence:()=> {
    return request({
      url: `fsystem/provences`,
      method: 'GET',
    }) 
  },
  getAutoSizeDataByGroupId:(groupId)=> {
    return request({
      url: `fsystem/autoSizeDataByGroupId?groupId=${groupId}`,
      method: 'GET',
    }) 
  },
  getSizeAttachs:(id)=> {
    return request({
      url: `land/sizeAttachs?id=${id}`,
      method: 'GET',
    }) 
  },
  saveCustomerFiles:(data)=> {
    return request({
      url: `fsystem/customerFiles`,
      method: 'POST',
      data
    }) 
  },
  getCustomerFiles:(id)=> {
    return request({
      url: `fsystem/customerFiles?customerId=${id}`,
      method: 'GET',
      
    }) 
  },
  delCustomerFiles:(id,path)=> {
    return request({
      url: `fsystem/customerFiles?id=${id}&path=${path}`,
      method: 'DELETE',
      
    }) 
  },
}
