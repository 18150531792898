

import { defineComponent, onMounted,computed,ref,watch } from 'vue'
import store from '@/store'
import router from '@/router'
import Screenfull from './Screenfull.vue'
import SearchText from './SearchText.vue'
import {guid} from '@/utils/help'
import wxlogin from 'vue-wxlogin'
import sysRequest from '@/api/sys'
import Hanbuger from './Hanbuger.vue'
import Navgater from './Navgater.vue'
import customerRequest from '@/api/customer'
import Language from './Language.vue'
import {message} from 'ant-design-vue'
import SelectTheam from './SelectTheam.vue'
import CBridge from '@/components/cBirdge/CBridge.vue'
import { generateTitle } from '@/utils/lang'
import {UserInfo} from '@/Interfaces/user/user'
import MenuItem from '@/Interfaces/user/MenuItem'
import { setTimestamp } from '@/utils/auth'
import { UserOutlined,KeyOutlined,MobileOutlined,MailOutlined } from '@ant-design/icons-vue'
declare class WxLogin {
  constructor(options: {
    self_redirect: boolean;
    id: string;
    appid: string;
    scope: string;
    redirect_uri: string;
    state: string;
    style: string;
    href: string;
  });
  // 如果WxLogin类有其他方法或属性，也应该在这里声明
}
export default defineComponent({
  setup() {
    const iconSetting = ref<string>('link')
    const open=ref(false)
    const showRegForm=ref(false)
    const isWxLogin=ref(false)
    const acceptTerm=ref(false)
    const islogin=ref(true)
    const showRegAccountForm=ref(false)
    const formInfo=ref()
    const validation=ref({phone:'',code:''})
    const loginInfo=ref<UserInfo>({username:'',password:'',remebmer:false})
    const resetPwd=ref({phone:'',password:'',code:'',phoneStatus:'',newPwdStatus:'',codeStatus:''})
    const activeKey=ref(1)
    const showApp=ref(true)
    const bindingPhone=ref()
    const getValidaCodeText=ref('获取验证码')
    const confirmExit=ref(false)
    const regAccountFormInfo=ref()
    const logout = () => {
      store.dispatch('user/logout')
      if(store.state.app.socket){
        store.state.app.socket.close()
      }
      confirmExit.value=false
      message.success("您已成功退出。")
      location.href="/main/bokes?k="+new Date().getTime().toString()
    }
    const bindingData=ref({code:'',phone:''})
    const newAccountInfo=ref<any>({})
    let hasStarted=false
    const showPhoneBinding=ref(false)
    const bingdingResult=ref({phone:'',openid:''})
    let bingdingT
    const keyChanged=(key)=>{
      if(key=='3' && !hasStarted){
        hasStarted=true
        bingdingT= window.setInterval(()=>{          
          sysRequest.getWxLoginResult(wxLoginstate.value).then(res=>{           
            if(res.data?.openid){  //取到了openId 证明用户扫码了并且同意了登录
              window.clearInterval(bingdingT)
              bingdingResult.value=res.data
              hasStarted=false
              if(!res.data.phone){  //没有绑定手机号，需要去绑定手机号
                showPhoneBinding.value=true
              }else{
                bindingData.value.code="_000000"   //默认验证码,可以直接通过默认验证码+手机号进行登录
                bindingData.value.phone=res.data.phone                               
                store.dispatch('user/codeLogin',bindingData.value).then((res) => {
                  setTimestamp()
                  const menu=menus.value.find(item=>item.path==='/main/buycar')
                  if(menu){
                    menu.enabled=true
                  }                  
                  if(res){
                    window.setTimeout(()=>{
                      showLoginForm.value=false
                      activeKey.value=1 
                    },1000)
                  }          
                })
                .catch((err) => {
                  message.warn(err.message)
                })
              }
            }
          })
        },3000)
      }
    }
    
    const orgId=computed(()=>{
      return store.getters.orgId
    })
    const userId=computed(()=>{
      return store.getters.userInfo.id
    })
    const menus = ref<MenuItem[]>([
      {
        title: generateTitle('main.index'),
        path: '/main/index',
        icon: 'personnel-manage',
        enabled:true
      },
      {
        title: generateTitle(orgId.value===1000?'main.bokes':'main.bokesBranch'),
        path: '/main/bokes',
        flag:'版型库浏览与下单',
        icon: 'personnel-manage',
        enabled:false
      },
      {
        title: generateTitle('main.cloths'),
        path: '/main/cloths',
        flag:'成衣定制浏览与下单',
        icon: 'personnel-manage',
        enabled:false
      },
      {
        title: generateTitle('main.retails'),
        path: '/main/retails',
        flag:'成衣现货浏览与下单',
        icon: 'personnel-manage',
        enabled:false
      },  
      {
        title: generateTitle('main.fabrics'),
        path: '/main/fabrics',
        flag:'面料浏览与下单',
        icon: 'personnel-manage',
        enabled:false
      },  
      {
        title: generateTitle('main.accessories'),
        path: '/main/accessories',
        flag:'辅料浏览与下单',
        icon: 'personnel-manage',
        enabled:false
      },  
      {
        title: generateTitle('main.decorations'),
        path: '/main/decorations',
        flag:'饰品浏览与下单',
        icon: 'personnel-manage',
        enabled:false
      },
      {
        title: generateTitle('main.buycar'),
        path: '/main/buycar',
        icon: 'personnel-manage',
        enabled:true
      },   
      {
        title: generateTitle('main.helpcenter'),
        path: '/main/helpcenter',
        flag:'教学中心',
        icon: 'personnel-manage',
        enabled:false
      }
    ])
    const scaleClick=()=>{
      router.push("/main/autoscale")
    }
    const selectedIndex = ref(0)
    const menuclick = (menu, index) => {
      selectedIndex.value = index      
      router.push(menu.path)
    }
    const bindPhoneButton=ref('获取验证码')
    const accountInfo=ref({
      username:store.getters.userInfo.loginName,
      password:'',
      newPassword:''
    })
    
    const bingding=()=>{            
      sysRequest.bindWxLogin(bindingData.value.phone,bingdingResult.value.openid).then(res=>{
        if(res.data>0){
          store.dispatch('user/codeLogin',bindingData.value).then((res) => {
            setTimestamp()
            if(res){
              const menu=menus.value.find(item=>item.path==='/main/buycar')
              if(menu){
                menu.enabled=true
              }  
              showLoginForm.value=false
              showPhoneBinding.value=true
              message.success('绑定成功,并自动完成登录。')
            }          
          })
          .catch((err) => {
            message.warn(err.message)
          })
        }
      })
    }
    const pwdError=ref(false)
    const resetPwdClick=()=>{      
      if(resetPwd.value.code=="" || resetPwd.value.code.length!=6){
        message.error('验证码不正确或未填写。')
        return
      }     
      pwdError.value=false
      resetPwd.value.newPwdStatus=""
      const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/; 
      if (!passwordRegex.test(resetPwd.value.password)) { 
        resetPwd.value.newPwdStatus="error"
        message.error('密码必须至少6位以上，并包含字母与数字。'); 
        pwdError.value=true
        return; 
      }
      customerRequest.resetPwd(resetPwd.value).then(res=>{
        message.success('密码重置成功！')
        islogin.value=true
      }).catch(err=>{
        message.error(err.message)
      })
    }
    const sendCode=()=>{
      //if(bindingPhone.value){
      //  bindingPhone.value.validate().then(res=>{
          if(validatePhoneNumber(bindingData.value.phone)){
            const token=guid().substring(0,8)   
            codeState.value.regPhone=""
            accountExists.value=false
            sysRequest.sendValideCodeForBinding(bindingData.value.phone,token).then(res=>{
              if(res.data==-1){
                message.error('获取验证码失败')
                return
              }else if(res.data==-2){
                message.error('手机号码不存在，请注册账号后再登录。')
                return
              }        
                 
              let index=60
              let intev=setInterval(()=>{
                index--
                bindPhoneButton.value=`${index}秒后重新获取`
                if(index==0){
                  bindPhoneButton.value='获取验证码'
                  clearInterval(intev)
                }
              },1000)
            })
          }else{
            message.error('请录入正确的手机号码！');
            codeState.value.regPhone='error'
          }
      //  })
      //}
    }
    const validatePhoneNumber=(phoneNumber)=> {
      const regex = /^1[3-9]\d{9}$/;
      return regex.test(phoneNumber);
    }
    //const wxloginData=ref({appid:'wx8ea559b871f290f1',redirectUrl:encodeURIComponent("http://v3.landidz.com/api/land/receiveCode")})
    const wxloginData=ref({appid:process.env.VUE_APP_APPID,redirectUrl:encodeURIComponent(process.env.VUE_APP_LOGIN_URL)})
    const wxLogin=()=>{
      isWxLogin.value=true
      // const obj = new WxLogin({
      //   self_redirect:true,
      //   id:"login_container", 
      //   appid: "wx8ea559b871f290f1", 
      //   scope: "snsapi_login", 
      //   redirect_uri:encodeURIComponent("http://v3.landidz.com/api/receiveCode"),
      //     state: store.getters.orgId,
      //   style: "black",
      //   href: ""
      //   });
        
    }
    const codeState=ref({code:'',phone:'',regPhone:'',loginName:''})
    const getValidaCodeForReg=()=>{
      if(validatePhoneNumber(newAccountInfo.value.cellphone)){
        const token=guid().substring(0,8)   
        codeState.value.regPhone=""
        codeState.value.phone=''
        codeState.value.loginName=''
        accountExists.value=false
        sysRequest.sendValideCodeForReg(newAccountInfo.value.cellphone,newAccountInfo.value.loginName,token).then(res=>{
          if(res.data==-1){
            getValidaCodeText.value="获取验证码失败"
            return
          }else if(res.data==-2){
            message.error(`手机号码${newAccountInfo.value.cellphone}已注册}`)
            codeState.value.regPhone='error'
            return
          }else if(res.data==-3){
            message.error(`登录名${newAccountInfo.value.loginName}已注册}`)
            codeState.value.loginName='error'
            accountExists.value=true
            return
          }
          let index=60
          let intev=setInterval(()=>{
            index--
            getValidaCodeText.value=`${index}秒后重新获取`
            if(index==0){
              getValidaCodeText.value='获取验证码'
              clearInterval(intev)
            }
          },1000)
        })
      }else{
        message.error('请录入正确的手机号码！');
        codeState.value.regPhone='error'
      }
    }
    const getValidaCode=(phone)=>{
      if(validatePhoneNumber(phone)){
        const token=guid().substring(0,8)   
        codeState.value.phone=""
        resetPwd.value.phoneStatus=''
        sysRequest.sendValideCode(phone,token).then(res=>{
          if(res.data==-1){
            getValidaCodeText.value="获取验证码失败"
            return
          }else if(res.data==-2){
            getValidaCodeText.value="手机号码没有注册"
            return
          }
          let index=60
          let intev=setInterval(()=>{
            index--
            getValidaCodeText.value=`${index}秒后重新获取`
            if(index==0){
              getValidaCodeText.value='获取验证码'
              clearInterval(intev)
            }
          },1000)
        })
      }else{
        message.error('请录入正确的手机号码！');
        codeState.value.phone='error'
        resetPwd.value.phoneStatus='error'
      }
    }
    const path=computed(()=>{
      return router.currentRoute.value.path
    })
    const viewAccount=()=>{
      router.push("/main/cuscenter/info")
    }
    const accountExists=ref(false)
    const regAccount=()=>{                 
      accountExists.value=false
      if(regAccountFormInfo.value){
        regAccountFormInfo.value.validate().then(res=>{
          customerRequest.getUserCount(newAccountInfo.value.loginName).then(res=>{
            if(res.data==0){
              newAccountInfo.value.orgId=store.getters.orgId
              newAccountInfo.value.displayLevel=0
              newAccountInfo.value.executeLevel=0
              newAccountInfo.value.sourceFrom="PC"
              newAccountInfo.value.displayLevelText='普通会员'
              newAccountInfo.value.disable=0
              newAccountInfo.value.blocked=0
              newAccountInfo.value.limit=0
              newAccountInfo.value.levelExpired=new Date().getTime()
              newAccountInfo.value.lastLogin=new Date().getTime()
              customerRequest.addClient(newAccountInfo.value).then(res=>{
                if(res.data>0){
                  store.dispatch('user/login',{"username":newAccountInfo.value.loginName,"password":newAccountInfo.value.password}).then((res) => {
                    setTimestamp()
                    const menu=menus.value.find(item=>item.path==='/main/buycar')
                    if(menu){
                      menu.enabled=true
                    }  
                    initWebSocket()
                    message.success("注册成功，已为您登入系统，您可以畅享服装定制啦。")
                    showRegAccountForm.value=false
                    showLoginForm.value=false
                  })
                  .catch((err) => {
                    message.warn(err.message)
                  })
                }
              }).catch(err=>{
                message.warn(err.message)
              })
            }else{
              accountExists.value=true
              message.warn(`${newAccountInfo.value.loginName}已存在，请换个登录名。`)
            }            
          })
        })
      }
      
      
    }
    const orgInfo=computed(()=>{
      return store.getters.orgInfo
    })
    const  validatePassword=(rule, value, callback)=> {
      // 正则表达式，要求密码至少6个字符，至少一个数字，一个小写和一个大写字母  
      const regex =/^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{6,}$/;  
      if (value === '') {  
        callback(new Error('请录入新密码!'));  
      } else if (!regex.test(value)) {  
        callback(new Error('密码至少6位，且包含字母与数字。'));  
      } else {  
        callback();  
      }
    }
    const retryValidatePassword=(rule, value, callback)=>{
      const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{6,}$/;  
      if (value === '') {  
        callback(new Error('请录入新密码!'));  
      } else if (!regex.test(value)) {  
        callback(new Error('密码必须6位，且包含字母与数字。'));  
      } else if(newAccountInfo.value.password!=value){  
        callback(new Error('两次密码不一致。'));  
      }else{
        callback();  
      }
    }
    const logined=computed(()=>{
      return store.getters.userInfo.id
    })
    const userInfo=computed(()=>{
      return store.getters.userInfo
    })
    const validateCode=(phoneNumber)=> {
      const regex = /^\d{6}$/;
      return regex.test(phoneNumber);
    }
    const reconnectInterval = 5000;
    let socketRef
    const initWebSocket = () => {
      if (socketRef) {
        socketRef.close();
      }
      const wsfapi = process.env.VUE_APP_BASE_WS + 'cus' + store.getters.userInfo.id;
      socketRef = new WebSocket(wsfapi);      
      socketRef.onopen=()=>{
        console.log('ws connected');
      };
      socketRef.onmessage=(event)=>{
        const msg = JSON.parse(event.data);
        console.log(msg)
        //if(msg.messageType==1){   //体形照片信息
          if(store.state.app.bodyImageMsg){
            store.state.app.bodyImageMsg(msg)
          }
        //}else{
          if(store.getters.onmessage){         
            store.getters.onmessage(event)
          }
        //}
      }
      socketRef.onclose = () => {
        console.log('WebSocket disconnected. Reconnecting in ' + reconnectInterval / 1000 + ' seconds...');
        setTimeout(initWebSocket, reconnectInterval); // 设置定时器进行重连
      };
      socketRef.addEventListener('error', (error) => {
        console.error('WebSocket error:', error);
      });
      store.state.app.socket=socketRef
    };
    const codeLogin=()=>{
      store.state.user.token=null 
      codeState.value.code=''
      codeState.value.phone=''
      if(!validateCode(validation.value.code)){
        message.warn('请输入正确的验证码')
        codeState.value.code='error'
        return
      } 
      if(validatePhoneNumber(validation.value.phone)){    
        store.dispatch('user/codeLogin',validation.value).then((res) => {
          setTimestamp()
          if(res){
            initWebSocket()
            showLoginForm.value=false
            const menu=menus.value.find(item=>item.path==='/main/buycar')
            if(menu){
              menu.enabled=true
            }  
          }          
        })
        .catch((err) => {
          message.warn(err.message)
        })
      }else{
        message.warn('请输入正确的手机号')
        codeState.value.phone='error'
      }
    }
    const loginClick=()=>{
      store.state.user.token=null      
      store.dispatch('user/login',loginInfo.value)
        .then((res) => {
          setTimestamp()
          initWebSocket()
          showLoginForm.value=false
          const menu=menus.value.find(item=>item.path==='/main/buycar')
          if(menu){
            menu.enabled=true
          }  
          message.success("登录成功")
        })
        .catch((err) => {
          message.warn(err.message)
        })
    }
    
    const showLoginForm=ref(false)
    const updatePwd=()=>{
      formInfo.value.validate().then(()=>{
        const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{6,}$/;  
        if(regex.test(accountInfo.value.newPassword)){
          sysRequest.updatePwd(accountInfo.value).then(res=>{
            open.value=false
            message.success('密码修改成功。')
            accountInfo.value.password=""
            accountInfo.value.newPassword=""
          }).catch(err=>{
            message.error(err.response.data.message)
          })
        }  
      })      
    }
    const myspace=()=>{
      router.push('/main/upgradeLevel')
    }
    const cuscenter=()=>{
      router.push('/main/cuscenter')
    }
    const suggest=()=>{
      router.push('/main/cuscenter/suggestion')
    }
    const scrolltop=ref(0)
    
    const wxLoginstate=ref({t:new Date().getTime(),orgId:store.getters.orgId})
    const previliges=computed(()=>{
      return store.getters.previliges
    })
    const t=ref(new Date().getTime())
    let tcount=10
    const getLevels=()=>{
      sysRequest.getLevels(store.getters.orgId).then(res=>{
        store.commit('user/setLevels',res.data)
      })
    }
    const isPad=computed(()=>{
      return store.getters.isPad
    })
    watch(()=>store.getters.isPad,()=>{
      console.log(store.getters.isPad)
    })
    onMounted(async ()=>{      
      await getLevels()
      const path=router.currentRoute.value.path
      const type=router.currentRoute.value?.query?.type
      const parentPath=router.currentRoute.value.meta.parentPath
      for(let i=0;i<menus.value.length;i++){
        if(path===menus.value[i].path || menus.value[i].path===parentPath){
          // if(type==='3' && menus.value[i].path===parentPath){  //饰品
          //   selectedIndex.value = 4
          // }else{
            selectedIndex.value = i
          // }
        }
      }
      store.state.user.loginCallback=()=>{
        showLoginForm.value=true
      }
      store.state.user.setMenuIndex=(index)=>{
        selectedIndex.value = index
      }
      const ele=document.querySelector('.app-main')
      if(ele){
        ele?.addEventListener('scroll', ()=>{
          scrolltop.value=ele.scrollTop
        })
      }
      if(!store.getters.userInfo.id){        
        const menu=menus.value.find(item=>item.path==='/main/buycar')
        if(menu){
          menu.enabled=false
        }  
      }
      
      const tt=window.setInterval(()=>{
        for(const menu of menus.value){
          if(menu.flag && previliges.value[menu.flag]){
            menu.enabled=true
          }
        }
        tcount--;
        if(tcount<=0){
          window.clearInterval(tt)
        }
      },200)
      if(store.getters.userInfo.id){
        initWebSocket()
      }
    })
    watch(()=>showLoginForm.value,()=>{
      if(!showLoginForm.value && bingdingT){
        window.clearInterval(bingdingT)
      }
    })
    return {
      iconSetting,myspace,formInfo,showRegAccountForm,newAccountInfo,getValidaCodeForReg,showPhoneBinding,previliges,
      logout,suggest,activeKey,loginInfo,regAccount,accountExists,codeLogin,wxLogin,bindingData,sendCode,orgId,showApp,
      store,path,updatePwd,loginClick,acceptTerm,regAccountFormInfo,wxloginData,wxLoginstate,bindPhoneButton,userId,
      menus,validatePassword,logined,showRegForm,retryValidatePassword,isWxLogin,t,bindingPhone,bingding,scaleClick,
      menuclick,cuscenter,showLoginForm,confirmExit,viewAccount,getValidaCodeText,keyChanged,userInfo,pwdError,orgInfo,
      selectedIndex,scrolltop,open,accountInfo,validation,getValidaCode,codeState,islogin,resetPwd,resetPwdClick,isPad
    }
  },
  components: {
    Hanbuger,UserOutlined,KeyOutlined,MobileOutlined,MailOutlined,
    Navgater,
    CBridge,
    SelectTheam,wxlogin,
    Language
  }
})
