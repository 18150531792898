import { createI18n } from 'vue-i18n'
import langEn from './lang/en'
import langZh from './lang/zh'
import store from '@/store'
const messages = {
  en: {
    msg: {
      ...langEn,
      test: 'Hello world',
      login:'Login',
      accountLogin:'Account Login',
      enterUser:'Please enter username',
      enterPwd:'Please enter password'
    }
  },
  zh: {
    msg: {
      ...langZh,
      test: '你好世界',
      login:'登录',
      accountLogin:'用户登录',
      enterUser:'请输入账号',
      enterPwd:'请输入密码',
      enterPhone:'请输入手机号',
      enterCode:'请输入验证码'
    }
  }
}
const getLanguage=()=> {
    const ss= store && store.getters && store.getters.language
    //console.log(ss)
    return ss
  }
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale:getLanguage(),
  messages
})
export default i18n
