import { createStore } from 'vuex'
import user from './modules/user'
import app from './modules/app'
import baseData from './modules/baseData'
// import business from './modules/business'
//import getters from '@/store/getters'
export default createStore({
  // state: {
  // },
  // mutations: {
  // },
  // actions: {
  // },
  getters: {
    token: (state: any) => state.user.token,
    userInfo: (state) => state.user.userInfo,
    levels:(state)=>state.user.levels,
    sideBarOpened:(state)=>state.app.sideBarOpened,
    language:(state)=>state.app.language,
    theme:(state)=>state.app.theme,
    tags:(state)=>state.app.tagsViewList,
    styleData:(state)=>state.user.styleData,
    categoryData:(state)=>state.user.categoryData,
    sizeData:(state)=>state.user.sizeData,  
    provences:(state)=>state.user.provences,   
    settings :(state)=>state.user.settings,  
    onmessage:(state)=>state.app.onmessage,
    previliges:(state)=>state.user.previliges,
    socket:(state)=>state.app.socket,
    orgId:(state)=>state.user.orgId,
    orgInfo:(state)=>state.user.orgInfo,
    isPad:(state)=>state.user.isPad,
    hasUserInfo: (state: any) => {
      return JSON.stringify(state.user.userInfo) !== '{}'
    }
  },
  modules: {
    user,
    app,
    baseData,
    // business
  }
})
