
import { defineComponent, onMounted,computed,reactive, ref } from 'vue'
import store from '@/store'
import prdRequest from '@/api/product'
import { message } from 'ant-design-vue';
import router from '@/router'
import {SelectTypes} from '@/Interfaces/user/DataItem';
import CardData from "@/Interfaces/user/CardData"
import { ProductType } from '@/Interfaces/enums/ProductType'
import { EnumOrderType } from '@/Interfaces/orders/EnumOrderType'
import ClothCard from '@/views/main/components/clothCard.vue'
export default defineComponent({
  components:{
    ClothCard
  },
  setup() {
    const seasonStrings=ref<Array<string>>([]) 
    const pageSize=ref(20)   
    const bokes=ref<Array<CardData>>([])
    const total=ref(0)
    const cType=ref(-1)
    const current=ref(1)
    const uploadfiles=ref<HTMLInputElement|null>(null); 
    const coverShow=ref(false)
    const season=ref({a:false,b:false,c:false,d:false})    
    const isOpen=ref({
      favorate:false,
      master:false,
      season:false,
      order:false,
      category:false,
      imageSearching:false
    })
    const categoryData =ref<Array<SelectTypes>>([]);
    const clothCondition=reactive({
      orgId:store.getters.userInfo.orgId,
      productType:5,   //产品类型 0、版型  1、面料  2、辅料  3、饰品  4成衣  
      customerId:store.getters.userInfo.id,
      searchValue:"",
      org:-1,
      seasonList:seasonStrings.value,
      categoryList:[],
      collection:null,
      season:null,
      category:null,
      sort:'title',
      self:null,
      favorites:"0",
      mounted:1,
      page:1,
      size:pageSize.value
    })
    const isPad=computed(()=>{
      return store.getters.isPad
    })
    const resetCondition=()=>{
      clothCondition.searchValue=""
      clothCondition.org=-1
      clothCondition.seasonList=[]
      clothCondition.categoryList=[]
      clothCondition.collection=null
      clothCondition.season=null
      clothCondition.category=null
      clothCondition.sort='title'
      clothCondition.favorites="0"
      clothCondition.page=1
      season.value={a:false,b:false,c:false,d:false}
      getAllProducts(1)
    }
    const getAllProducts=async (pageIndex)=>{
      clothCondition.page=pageIndex  
      clothCondition.seasonList =seasonStrings.value
      clothCondition.size=pageSize.value 
      let res
      if(store.getters.orgId===1000){
        res=await prdRequest.getProducts(clothCondition)
      }else{
        clothCondition.productType=0     
        res=await prdRequest.getBranchBokeCloths(clothCondition)
      }
      if(res.data.list){
        total.value=res.data.total
        let list:Array<CardData>=[]
        let level
        if(store.getters.levels && store.getters.levels.length>0){
          if(store.getters.userInfo.id && store.getters.userInfo.executeLevel>0 && store.getters.userInfo.levelExpired>new Date().getTime()){
            level=store.getters.levels.find(itm=>itm.id=== store.getters.userInfo.executeLevel)
          }
          if(!level){
            level=store.getters.levels[store.getters.levels.length-1]
          }
        } 
        res.data.list.forEach(itm=>{
          list.push({
            code:itm.code,orgName:itm.orgName, 
            url:itm.mainImageUrl,
            productType:ProductType.成衣定制,
            favorite:itm.favorite,
            orgId:store.getters.orgId,sizeGroup:itm.sizeGroup,
            description:itm.clothTitle?itm.clothTitle:itm.title,
            price2:itm.retailPrice,
            price1:(level?itm.retailPrice*level.bokeClothDiscount*0.01:itm.memberPrice).toFixed(0)/1,
            id:itm.id
          })      
        })
        bokes.value=list
      } 
    }
    const categoryChanged=(val)=>{
      getAllProducts(1)
    }
    const uploadImage=()=>{
      uploadfiles.value?.click()
    }
    const previliges=computed(()=>{
      return store.getters.previliges
    })
    const cardClick=(item)=>{
      if(item.productType==ProductType.版型 || item.productType==ProductType.成衣定制){        
        if(!previliges.value['商品明细弹窗查看'] || isPad.value){
          router.push({  
            path: '/main/clothdetail', 
            query: {id:item.id}
          });
        }else{
          const url = `${window.location.origin}/main/clothdetail?id=${item.id}`;
          window.open(url, '_blank'); 
        }
      }else{
        router.push({  
          path: '/main/retailCloth',  
          query: {id:item.id}
        });
      }
      
    }
    const sort=(key)=>{
      clothCondition.sort=key
      getAllProducts(1)
    }
    const loadCategoryData = () =>{      
      if(store.getters.categoryData && store.getters.categoryData.length>0){
        categoryData.value=[]
        store.getters.categoryData.forEach(item => {
          categoryData.value.push({label:item.name,value:item.id})
        })        
      }else{
        store.dispatch('user/getCategoryData').then((res) => {
          categoryData.value=[]
          res.forEach(item => {
          categoryData.value.push({label:item.name,value:item.id})
        })
        }).catch((err) => {
          console.warn(err.message)
        })
      }
    }
    const setSeasonId = () =>{
      const trueKeys = Object.keys(season.value).filter(key => season.value[key] === true); 
      const mapping = {a: 1,b: 2,c: 3,d: 4};
      const numArray = trueKeys.map(str => mapping[str]); 
      seasonStrings.value = trueKeys.map(str => mapping[str]); 
      clothCondition.seasonList=seasonStrings.value
      getAllProducts(1)          
    }
    const expend=(option)=>{
      cType.value=option
      setTimeout(()=>{
        if(cType.value==0){
          isOpen.value.season=false
          isOpen.value.order=false
          isOpen.value.master=false
          isOpen.value.category=false
          isOpen.value.imageSearching=false
          isOpen.value.favorate=!isOpen.value.favorate        
        }else if(cType.value==1){
          isOpen.value.favorate=false
          isOpen.value.order=false
          isOpen.value.master=false
          isOpen.value.category=false
          isOpen.value.imageSearching=false
          isOpen.value.season=!isOpen.value.season
        }else if(cType.value==3){
          isOpen.value.master=false
          isOpen.value.favorate=false
          isOpen.value.season=false
          isOpen.value.category=false
          isOpen.value.imageSearching=false
          isOpen.value.order=!isOpen.value.order
        }else if(cType.value==2){
          isOpen.value.season=false
          isOpen.value.order=false
          isOpen.value.favorate=false
          isOpen.value.category=false
          isOpen.value.imageSearching=false
          isOpen.value.master=!isOpen.value.master
        }else if(cType.value==4){
          isOpen.value.season=false
          isOpen.value.master=false
          isOpen.value.order=false
          isOpen.value.favorate=false
          isOpen.value.imageSearching=false
          isOpen.value.category=!isOpen.value.category
        }else if(cType.value==5){
          isOpen.value.season=false
          isOpen.value.master=false
          isOpen.value.order=false
          isOpen.value.favorate=false
          isOpen.value.category=false
          isOpen.value.imageSearching=!isOpen.value.imageSearching
        }
        
        if(!isOpen.value.favorate && !isOpen.value.master && !isOpen.value.order && !isOpen.value.season && !isOpen.value.category && !isOpen.value.imageSearching){
          cType.value=-1
          coverShow.value=false
        }else{
          coverShow.value=true
        }
      },50)
    }

    const closeAll=()=>{
      isOpen.value.season=false
      isOpen.value.master=false
      isOpen.value.order=false
      isOpen.value.favorate=false 
      isOpen.value.category=false 
      isOpen.value.imageSearching=false 
      setTimeout(() => {
        cType.value=-1
      }, 50);
    }
    onMounted(()=>{
      if(isPad.value){
        pageSize.value=16
      }
      getAllProducts(1)
      loadCategoryData()
    })
    return {
      getAllProducts,bokes,clothCondition,total,current,uploadImage,cardClick,isOpen,cType,expend,pageSize,coverShow,closeAll,season,categoryChanged,
      setSeasonId,categoryData,uploadfiles,sort,resetCondition,isPad
    }
  }
})
