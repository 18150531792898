
import { useRoute, RouteLocationMatched } from 'vue-router'
import { watch, ref, defineComponent } from 'vue'
import { generateTitle } from '@/utils/lang'
import { isNull } from '@/utils/route'
export default defineComponent({
setup(){
  const breadPath = ref<RouteLocationMatched[]>([])
  const route = useRoute()
  breadPath.value = route.matched.filter((r) => !isNull(r.meta))

  const currentPath = ref('')
  const goback = () => {
    window.history.back()
  }
  watch(route, () => {
    breadPath.value = route.matched.filter((r) => !isNull(r.meta))
    currentPath.value = route.path
  })
  return {
    generateTitle,currentPath,breadPath,goback
  }
}
})

