
import { defineComponent, ref } from 'vue'
import { generateNewStyle } from '@/utils/theme'
import store from '@/store'
export default defineComponent({
  setup() {
    const visible = ref<boolean>(false)
    const showModal = () => {
      visible.value = true
    }
    // 预定义色值
    const predefineColors = ref<string[]>([
      '#ff4500',
      '#ff8c00',
      '#ffd700',
      '#90ee90',
      '#00ced1',
      '#1e90ff',
      '#c71585',
      'rgba(255, 69, 0, 0.68)',
      'rgb(255, 120, 0)',
      'hsl(181, 100%, 37%)',
      'hsla(209, 100%, 56%, 0.73)',
      '#c7158577',
      '#1890ff'
    ])
    const handleOk = () => {
      visible.value = false
    }
    const handleCancle = () => {
      visible.value = false
    }
    const selectColor = (color: string) => {
      const theme = {
        primaryColor: color,
        linkColor: color
      }      
      document.documentElement.style.setProperty('--activeColor', color);
      generateNewStyle(theme)
      store.commit('app/setTheme', theme)
    }
    return {
      handleOk,
      handleCancle,
      selectColor,
      showModal,
      visible,
      predefineColors
    }
  }
})
