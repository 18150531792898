import type { App } from "vue";

import { CBridge } from "./cBirdge";
import { CButton } from "./cButton";
import { CForm } from "./cForm";
import { CInput } from "./cInput";
import { CInputNumber } from "./cInputNumber";
import { CModal } from "./cModal";
import { CPagination } from "./cPagination";
import { CSelect ,CSelectOption} from "./cSelect";
import { CTable } from "./cTable";
import { CTabs, CTabPane } from "./cTabs";
import { CUpload } from "./cUpload";
import { CRadioGroup } from "./cRadio";
import { Checkbox, CheckboxGroup } from "./cCheckbox";
import { CalendarPicker } from "./calendarPicker";
import { FugleTable } from "./FugleTable";
import { Validator } from "./validator";
import { CDivider } from "./cDivider";
// 所有组件列表
const components = [
  CBridge,
  CButton,
  Validator,
  FugleTable,
  CForm,
  CInput,
  CInputNumber,
  CModal,
  CPagination,
  CSelect,
  CSelectOption,
  CTable,
  CTabs,
  CTabPane,
  CUpload,
  CRadioGroup,
  Checkbox,
  CheckboxGroup,
  CalendarPicker,
  CDivider
]

export default {
  install: (app: App) => {
    components.forEach(component => app.component(component.name, component))
  }
}
