import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/logo-white.png'
import _imports_1 from '@/assets/images/logo-short.png'


const _withScopeId = n => (_pushScopeId("data-v-d399910a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wraper" }
const _hoisted_2 = { class: "logo" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: ""
}
const _hoisted_4 = {
  key: 1,
  src: _imports_1,
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.store.getters.sideBarOpened)
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : (_openBlock(), _createElementBlock("img", _hoisted_4))
    ])
  ]))
}