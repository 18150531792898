export default {

  lang: {    
    "rangeWeekPlaceholder": [
      "开始周",
      "结束周"
    ],
  },
  login: {
    title: '用户登录',
    loginBtn: '登录',
    usernameRule: '用户名为必填项',
    passwordRule: '密码不能少于6位',
    
  },
  route: {
    main: {
      index: '首页',
      bokes: '版型库',
      bokesBranch: '款式库',
      cloths: '成衣定制',
      fabrics: '面料',
      fabricdetails:'面料详情',
      clothdetail:'成衣定制详情',
      decorations:'饰品',
      accessories:'辅料',
      buycar: '购物车',
      customercenter: '个人中心',
      bokemakingorder:'量体定制',
      retails:'成衣现货',
      helpcenter: '教学中心',
      tailoredBokes: '定制版型订单',
      tailoredCloths: '成衣定制订单',
      tailoredMade: '量体定制订单',
      videoTeching: '课程订单',
      orderconfirm:'订单确认',
      retailDetails:'成衣详情',
      cuscenter:{
        title:'个人信息',
        info:"个人资料",
        favourite:'我的收藏',
        makingCloth:'版型制成衣',
        notes:'优惠券',
        cloud:'蓝迪云盘',
        suggestion:'我的反馈建议',
        myfeet:'我看过的',
        shippingAddr:'我的收货地址',
        orders:'我的订单',
        custombokeorderdetails:'我的订单 / 版型定制订单详情',
        clothaftersales:'我的订单 / 成衣售后订单详情',
        branchs:'我的门店信息',
        myUpgrades:'购买会员订单',
        scaleOrders:'智能量体订单',
        courseOrders:'视频课程订单'
      }
    },
    products: {
      bokes: '版型管理',
      cloths: '成衣管理',
      clothDetails: '成衣明细',
      fabrics: '面料管理',
      fabricDetails:'面料详情'
    },
    systemTitle: '系统设置',
    system: {
      clothCategory: '服装类别',
      clothStyle: '服装风格',
      clothSize: '服装尺码',
      productUnit: '商品单位',
      clothSizeViewer: '服装尺码详情'
    }

  },
  toast: {
    switchLangSuccess: '切换语言成功'
  },
  tagsView: {
    refresh: '刷新',
    closeRight: '关闭右侧',
    closeOther: '关闭其他'
  },
  theme: {
    themeColorChange: '主题色更换',
    themeChange: '主题更换'
  },
  universal: {
    confirm: '确定',
    cancel: '取消'
  },
  navBar: {
    themeChange: '主题修改',
    headerSearch: '页面搜索',
    screenfull: '全屏替换',
    lang: '国际化',
    guide: '功能引导',
    home: '首页',
    course: '课程主页',
    logout: '退出登录'
  },
  
  
}
