import request from '@/utils/request'
import store from '@/store'
export default {
  getProducts: (data) => {
    if(!data.orgId){
      data.orgId=store.getters.orgId
    }
    return request({
      url: 'land/allProducts',
      method: 'POST',
      data
    })
  },
  getBranchProducts: (data) => {
    if(!data.orgId){
      data.orgId=store.getters.orgId
    }
    return request({
      url: 'land/branchProducts',
      method: 'POST',
      data
    })
  },
  getBranchBokeCloths: (data) => {
    if(!data.orgId){
      data.orgId=store.getters.orgId
    }
    return request({
      url: 'land/branchBokeCloths',
      method: 'POST',
      data
    })
  },
  getBokeCloths: (data) => {
    data.orgId=store.getters.orgId
    return request({
      url: 'land/bokeCloths',
      method: 'POST',
      data
    })
  },
  addProduct: (data) => {
    return request({
      url: 'product/addProduct',
      method: 'POST',
      data
    })
  },
  getOrderAllSize: ({orgId,orderType,sizeId,phone}) => {
    return request({
      url: `prd/orderAllSize?orgId=${orgId}&orderType=${orderType}&sizeId=${sizeId}&phone=${phone}`,
      method: 'GET',
    })
  },
  getOrderAttachsByIds: (ids,member,callback) => {
    const myHeaders = new Headers();
      myHeaders.append("token", store.getters.token);
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      fetch(process.env.VUE_APP_BASE_API+`/prd/orderAttachsByIds?ids=${ids.join('-')}&member=${member}`, requestOptions as any)
        .then(response => {
          if (!response.ok) {
            throw new Error(`网络错误！状态码: ${response.status}`);
          }
          return response.blob();
        })
        .then(blob => {
          if(callback){
            callback(blob)
          }          
        })
        .catch(error => console.error('Error:', error));
  },
  getOrderAttachs: ({id,loadboke},callback) => {
    const myHeaders = new Headers();
      myHeaders.append("token", store.getters.token);
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      fetch(process.env.VUE_APP_BASE_API+`/prd/orderAttachs?orderDetailId=${id}&loadboke=${loadboke}`, requestOptions as any)
        .then(response => {
          if (!response.ok) {
            throw new Error(`网络错误！状态码: ${response.status}`);
          }
          return response.blob();
        })
        .then(blob => {
          if(callback){
            callback(blob)
          }          
        })
        .catch(error => console.error('Error:', error));
  },
  editProduct: (data) => {
    return request({
      url: 'product/editProduct',
      method: 'POST',
      data
    })
  },
  getAttachs:(orgid,prdId) => {
    return request({
      url: `prd/attachs?orgid=${orgid}&prdid=${prdId}`,
      method: 'GET',
    })
  },
  getProductSizes: (id,orgid) => {
    if(!orgid){
      orgid=store.getters.orgId
    }
    return request({
      url: `land/productsizes?prdid=${id}&orgid=${orgid}`,
      method: 'GET',
    })
  },
  getSizeGroupProducts: (id) => {
    const orgId=store.getters.orgId
    return request({
      url: `land/sizeGroupProducts/${id}?orgId=${orgId}`,
      method: 'GET',
    })
  },
  getSizeGroupProductsById: (id) => {
    const orgId=store.getters.orgId
    return request({
      url: `land/sizeGroupProductsById/${id}?orgId=${orgId}`,
      method: 'GET',
    })
  },
  getGroupBokesviewer: (id) => {
    const orgId=store.getters.orgId
    return request({
      url: `land/groupBokesviewer/${id}?orgId=${orgId}`,
      method: 'GET',
    })
  },
  
  getGroupCloths: (id) => {
    return request({
      url: `prd/groupCloths/${id}`,
      method: 'GET',
    })
  },  
  getGroupClothsviewer: (id,productType) => {
    const orgId=store.getters.orgId
    return request({
      url: `land/groupClothsviewer/${id}?orgId=${orgId}&productType=${productType}`,
      method: 'GET',
    })
  },
  getProduct: (id) => {
    const orgId=store.getters.orgId
    if(orgId===1000){  //平台环境
      return request({
        url: 'land/product/'+id,
        method: 'GET',
      })
    }else{
      return request({
        url: 'land/branchProduct/'+id+'?orgId='+orgId,
        method: 'GET',
      })
    }
    
  },
  getGroupProduct: (id) => {
    return request({
      url: `land/groupProduct/${id}?orgId=${store.getters.orgId}`,
      method: 'GET',
    })
  },
  getGroupProductById: (id) => {
    return request({
      url: `land/groupProductById/${id}?orgId=${store.getters.orgId}`,
      method: 'GET',
    })
  },
  
  deleteProductAttach: (id) => {
    return request({
      url: 'product/deleteProductsAttach/'+id,
      method: 'DELETE',
    })
  },
  productMounted: (data) => {
    return request({
      url: 'product/editProductMounted',
      method: 'POST',
      data
    })
  },
  
}

