
import { defineComponent, onMounted, ref,computed } from 'vue'
import router from '@/router'
import store from '@/store'
import { useRoute } from 'vue-router'
import { generateTitle } from '@/utils/lang'
export default defineComponent({
  setup() {
    const title=ref('个人资料')
    const route=useRoute()
    const menuClick=(path,t)=>{
      title.value=generateTitle('main.cuscenter.'+t)   
      console.log(path)
      if(path==='/main/cuscenter/orders'){
        router.push({  
        path: path, 
        query: {  
          type:1
        }
      });
      }else{
        router.push({  
          path: path,
        });
      }
      
    }
    const orgId=computed(()=>{
      return store.getters.orgId
    })
    const mybranchs=(role)=>{
      router.push({
          path:'/main/cuscenter/branchs', 
          query: {newbrandId:-1,role}
        })
    }
    const back=()=>{
      window.history.back()
    }
    const previliges=computed(()=>{
      return store.getters.previliges
    })
    onMounted(()=>{
      title.value=generateTitle(route.meta.title)
    })
    return {
      menuClick,title,back,generateTitle,mybranchs,orgId,previliges
    }
  }
})
