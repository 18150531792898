import axios from 'axios'
import { Theme } from '@/Interfaces/user/user'
/**
 * 获取当前 element-plus 的默认样式表
 */
export const generateNewStyle = async (color: Theme) => {
  //const version = require('element-plus/package.json').version
  const url = `/antd.css`
  let { data } = await axios(url)
  const colorMap: any = {
    '#1890ff': color.primaryColor,
    '#40a9ff': color.linkColor
  }
  if (color && color.primaryColor) {
    Object.keys(colorMap).forEach(key => {
      const value = colorMap[key]
      data = data.replace(new RegExp(key, 'ig'), value)
    })
  }
  // 把获取到的数据筛选为原样式模板
  //const acss=document.getElementById("#antcss")    
  for (let i = 0; i < document.head.childNodes.length; i++) {
    const node = document.head.childNodes[i] as HTMLElement
    if (node.id === 'antcss') {
      document.head.removeChild(document.head.childNodes[i])
    }
  }
  const style = document.createElement('style')
  style.setAttribute('id', 'antcss')
  style.setAttribute('type', 'text/css')
  style.innerText = data
  document.head.appendChild(style)


  return data
}