import request from '@/utils/request'
import store from '@/store'
export default {
  saveAddress: (data: any) => {
    return request({
      url: 'customer/customerAddress',
      method: 'POST',
      data
    })
  },
  addFavorite: (data: any) => {
    return request({
      url: 'customer/favorite',
      method: 'POST',
      data
    })
  },
  
  saveCusNote: (note) => {
    return request({
      url: `customer/cusNotes`,
      method: 'POST',
      data:note
    })
  },
  saveCustomerNote: (data: any) => {
    return request({
      url: 'customer/customerNote',
      method: 'POST',
      data
    })
  },
  getOrgNote: () => {
    const orgId=store.getters.orgId
    return request({
      url: `customer/orgNote?orgId=${orgId}`,
      method: 'GET'      
    })
  },
  addFootprint: (data: any) => {
    if(store.getters.userInfo.id){
      return request({
        url: 'customer/footprint',
        method: 'POST',
        data
      })
    }
    
  },
  getMyfootprint: (data: any) => {
    return request({
      url: 'customer/myfootprint',
      method: 'POST',
      data
    })
  },
  resetPwd: (data: any) => {
    const orgId=store.getters.orgId
    return request({
      url: `land/resetPwd?phone=${data.phone}&password=${data.password}&code=${data.code}&orgId=${orgId}`,
      method: 'GET',
      data
    })
  },
  deleteFootprint:(id)=>{
    return request({
      url: `customer/footprint?id=${id}`,
      method: 'DELETE'
    })
  },
  addClient: (data: any) => {
    return request({
      url: 'land/addClient',
      method: 'POST',
      data
    })
  },
  getUserCount:(loginName)=>{
    return request({
      url: `land/userCount?loginName=${loginName}`,
      method: 'GET'
    })
  },
  getFavorite:(cusId,prdId)=>{
    return request({
      url: `customer/favorite?customerId=${cusId}&productId=${prdId}`,
      method: 'GET'
    })
  },
  getFavorites:(cusId)=>{
    return request({
      url: `customer/favorites?customerId=${cusId}`,
      method: 'GET'
    })
  },
  getFavoritesWithImages:(cusId)=>{
    return request({
      url: `customer/favoritePrds?id=${cusId}`,
      method: 'GET'
    })
  },
  delFavorite:(id)=>{
    return request({
      url: `customer/favorite?id=${id}`,
      method: 'DELETE'
    })
  },
  addAdvices: (data: any) => {
    return request({
      url: 'customer/advices',
      method: 'POST',
      data
    })
  },
  getAdvices: (id) => {
    return request({
      url: `customer/advices?customerId=${id}`,
      method: 'GET',
      
    })
  },
  savePayment: (data: any) => {
    return request({
      url: 'customer/savepayment',
      method: 'POST',
      data
    })
  },
  updateMemerOrderUrl: (orderNumber,url) => {
    return request({
      url: `customer/updateMemerOrderUrl?orderNumber=${orderNumber}&url=${url}`,
      method: 'GET',      
    })
  },
  setClientLevel: (id,levelId) => {
    return request({
      url: `customer/setClientLevel?id=${id}&level=${levelId}`,
      method: 'GET',
      
    })
  },
  clientById: (customerId) => {
    return request({
      url: `customer/clientById/${customerId}`,
      method: 'GET',
      
    })
  },
  getGenewals: (customerId) => {
    return request({
      url: `customer/renewalsByCustomerId?customerId=${customerId}`,
      method: 'GET',
      
    })
  },
  createOrder: (data) => {
    return request({
      url: `customer/createOrder`,
      method: 'POST',
      data
    })
  },
  unbindAccount: (id) => {
    return request({
      url: `customer/unbindAccount?id=${id}`,
      method: 'DELETE',
      
    })
  },
  getAddress: (customerId) => {
    return request({
      url: `customer/customerAddress/${customerId}`,
      method: 'GET',
      
    })
  },
  setDefault: (id,cusid) => {
    return request({
      url: `customer/setDefault?id=${id}&cusid=${cusid}`,
      method: 'GET',
      
    })
  },

  getDefaultAddress: (customerId) => {
    return request({
      url: `customer/defaultAddress/${customerId}`,
      method: 'GET',
      
    })
  },
  getSingleAddress: (id) => {
    return request({
      url: `customer/address/${id}`,
      method: 'GET',
      
    })
  },
  getMyNotesByCustomerId: (id) => {
    return request({
      url: `customer/myNotesByCustomerId?customerId=${id}`,
      method: 'GET',
      
    })
  },
  getMyValideNotesByCustomerId: (id) => {
    return request({
      url: `customer/myValideNotesByCustomerId?customerId=${id}`,
      method: 'GET',
      
    })
  },
  takeNote: (id,statu) => {
    return request({
      url: `customer/takeNote?id=${id}&statu=${statu}`,
      method: 'GET',
      
    })
  },
  delCustomerAddress: (id) => {
    return request({
      url: `customer/customerAddress?id=${id}`,
      method: 'DELETE',
      
    })
  },
  updateClientInfo: (data) => {
    return request({
      url: `customer/updateClientInfo`,
      method: 'POST',
      data
    })
  }
}