export default {
  
  route: {  
    main: {
      index: 'Home',
      bokes: 'Boke Library',
      cloths: 'Cloth Customization', //成衣定制
      fabrics: 'Fabric',
      fabricdetails:'Fabric Details',
      clothdetail:'成衣定制详情',
      decorations:'饰品', //饰品
      accessories:'辅料',
      buycar: 'Shopping Cart',
      customercenter: 'Customer Center',
      bokemakingorder:'量体定制',
      retails:'成衣现货',
      helpcenter: '帮助中心',
      tailoredBokes: '定制版型订单',
      tailoredCloths: '成衣定制订单',
      tailoredMade: '量体定制订单',
      videoTeching: '课程订单',
      orderconfirm:'订单确认',
      retailDetails:'成衣详情',
      cuscenter:{
        title:'个人信息',
        info:"个人资料",
        favourite:'我的收藏',
        makingCloth:'版型制成衣',
        notes:'优惠券',
        cloud:'蓝迪云盘',
        suggestion:'我的反馈建议',
        myfeet:'我看过的',
        shippingAddr:'我的收货地址',
        orders:'我的订单',
        custombokeorderdetails:'我的订单 / 版型定制订单详情',
        clothaftersales:'我的订单 / 成衣售后订单详情',
        branchs:'我的门店信息',
        myUpgrades:'会员升级订单',
        scaleOrders:'智能量体订单',
        courseOrders:'视频课程订单'
      }
    },
    products: {
      bokes: '版型管理',
      cloths: '成衣管理',
      clothDetails: '成衣明细',
      fabrics: '面料管理',
      fabricDetails:'面料详情'
    },
    systemTitle: '系统设置',
    system: {
      clothCategory: '服装类别',
      clothStyle: '服装风格',
      clothSize: '服装尺码',
      productUnit: '商品单位',
      clothSizeViewer: '服装尺码详情'
    }
    
},
  
}
