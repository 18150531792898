import baseDataApi from '@/api/baseData'
export default {
  namespaced: true,
  actions: {
    async uploadImage(context, data) {
      const res = await baseDataApi.uploadImage(data)
      return res
    },
    async getInputCode(context, value) {
      const res = await baseDataApi.getInputCode(value)
      return res
    },
    //#region 时间段模板
    async getAllTimeTemplate(context) {
      const res = await baseDataApi.getAllTimeTemplate()
      return res
    },
    async getTimeTemplateBySearch(context, data) {
      const res = await baseDataApi.getTimeTemplateBySearch(data)
      return res
    },
    async insertTimeTemplate(context, data) {
      const res = await baseDataApi.insertTimeTemplate(data)
      return res
    },
    async updateTimeTemplate(context, data) {
      const res = await baseDataApi.updateTimeTemplate(data)
      return res
    },
    async updateTimeTemplateStatus(context, data) {
      const res = await baseDataApi.updateTimeTemplateStatus(data)
      return res
    },
    //#endregion
    //#region 院区信息
    async getAllHospitalareas(context) {
      const res = await baseDataApi.getAllHospitalareas()
      return res
    },
    async getHospitalareasBySearch(context, data) {
      const res = await baseDataApi.getHospitalareasBySearch(data)
      return res
    },
    async insertSysHospitalArea(context, data) {
      const res = await baseDataApi.insertSysHospitalArea(data)
      return res
    },
    async updateSysHospitalAreaById(context, data) {
      const res = await baseDataApi.updateSysHospitalAreaById(data)
      return res
    },
    async updateSysHospitalAreaStatus(context, data) {
      const res = await baseDataApi.updateSysHospitalAreaStatus(data)
      return res
    },
    //#endregion
    //#region 科室信息
    async getAllDepartments(context) {
      const res = await baseDataApi.getAllDepartments()
      return res
    },
    async getDepartmentsBySearch(context, data) {
      const res = await baseDataApi.getDepartmentsBySearch(data)
      return res
    },
    async insertSysDepartments(context, data) {
      const res = await baseDataApi.insertSysDepartments(data)
      return res
    },
    async updateSysDepartmentsById(context, data) {
      const res = await baseDataApi.updateSysDepartmentsById(data)
      return res
    },
    async updateSysDepartmentsStatus(context, data) {
      const res = await baseDataApi.updateSysDepartmentsStatus(data)
      return res
    },
    //#endregion
    //#region 医生信息
    async getAllDoctors(context) {
      const res = await baseDataApi.getAllDoctors()
      return res
    },
    async getDoctorsBySearch(context, data) {
      const res = await baseDataApi.getDoctorsBySearch(data)
      return res
    },
    async insertSysDoctors(context, data) {
      const res = await baseDataApi.insertSysDoctors(data)
      return res
    },
    async updateSysDoctorsById(context, data) {
      const res = await baseDataApi.updateSysDoctorsById(data)
      return res
    },
    async updateSysDoctorsStatus(context, data) {
      const res = await baseDataApi.updateSysDoctorsStatus(data)
      return res
    },
    async getDoctorsByDepartmentId(context, id) {
      const res = await baseDataApi.getDoctorsByDepartmentId(id)
      return res
    },
    //#endregion
  }
}