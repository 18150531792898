
import { useRoute, RouteMeta } from 'vue-router'
import { defineComponent, watch,computed,onMounted,ref } from 'vue'
import router from '@/router'
import { generateTitle } from '@/utils/lang'
import store from '@/store'
export default defineComponent({
  setup() {
    const route = useRoute()
    const getTitle = (meta: RouteMeta): string => {
      if (meta) {
        return generateTitle(meta.title)
      } else {
        return ''
      }
    }
    const getKeepAlive=(path)=>{
      const paths=['/main/bokes','/main/cloths','/main/decorations','/main/fabrics','/main/accessories']
      const n=paths.includes(path)
      return n
    }
    const path=computed(()=>{
      return router.currentRoute.value.path
    })
    watch(route, (from, to) => {
      const { fullPath, meta, name, params, path, query } = to
      store.commit('app/addTagView', {
        fullPath,
        meta,
        name,
        params,
        path,
        query,
        title: getTitle(meta)
      })
    })
    const scrolltop=ref(0)
    onMounted(() => {
      const ele=document.querySelector('.app-main')
      if(ele){
        ele?.addEventListener('scroll', ()=>{
          scrolltop.value=ele.scrollTop
        })
      }
      
    })
    return {
      route,path,scrolltop
    }
  }
})

