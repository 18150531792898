
import { defineComponent, ref } from 'vue'
import { MenuInfo } from '@/Interfaces/user/user'
import { useI18n } from 'vue-i18n'
import store from '@/store'
import { message } from 'ant-design-vue'
export default defineComponent({
  setup(){
    const visible = ref(false)
    const i18n = useI18n()
    const handleMenuClick = (e: MenuInfo) => {
      if (e.key === '3') {
        visible.value = true
      }
    }
    const langClick = (lang: string) => {
      store.commit('app/changeLanguage', lang)
      i18n.locale.value = lang
      message.success('切换语言成功')
      window.setTimeout(()=>{
        window.location.reload()
      },1000)
    }
    return {
      handleMenuClick,langClick,visible
    }
  }
})

