
import { defineComponent,watch,computed} from "vue";
export default defineComponent({
  name:'Validator',
  props:{  
    isValid: {
      type: Boolean,
      required: true,
      default:true
    },
    error: {
      type: String
    },

  },
  setup(props) {   
    const isvalid=computed(()=>{
      return props.isValid
    })
    const err=computed(()=>{
      return props.error

    })
    return {
      err,isvalid
    }
  }
})
