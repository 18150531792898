
import Navbar from './components/Navbar.vue'
import AppMain from './components/AppMain.vue'
import Sidebar from './components/sidebar/index.vue'
import LevelCard from '@/views/main/components/levelCard.vue'
import router from '@/router'
import variables from '@/assets/styles/variables.module.scss'
import store from '@/store'
import { defineComponent, ref, onMounted, computed } from 'vue'
import sysRequest from '@/api/sys'
import Level,{LevelAmount} from '@/Interfaces/customer/Level'
export default defineComponent({
  components: {
    Navbar, AppMain, Sidebar,LevelCard
  },
  setup() {
    const path=computed(()=>{
      return router.currentRoute.value.path
    })    
    const scrolltop=ref(0)
    onMounted(() => {
      const ele=document.querySelector('.app-main')
      if(ele){
        ele?.addEventListener('scroll', ()=>{
          scrolltop.value=ele.scrollTop
        })
      }
      
    })
    return {
      variables, store,path,scrolltop
    }
  }
})
