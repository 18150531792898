import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-364a62f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lang-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!

  return (_openBlock(), _createBlock(_component_a_dropdown, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event))
  }, {
    overlay: _withCtx(() => [
      _createVNode(_component_a_menu, { onClick: _ctx.handleMenuClick }, {
        default: _withCtx(() => [
          _createVNode(_component_a_menu_item, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.langClick('zh'))),
            key: "1"
          }, {
            default: _withCtx(() => [
              _createTextVNode("简体中文")
            ]),
            _: 1
          }),
          _createVNode(_component_a_menu_item, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.langClick('en'))),
            key: "2"
          }, {
            default: _withCtx(() => [
              _createTextVNode("English")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_svg_icon, {
          icon: "language",
          class: "svgi",
          style: {"font-size":"24px","margin-right":"20px"}
        })
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}