import request from '@/utils/request'
export default {
  //图片上传
  uploadImage: (data) => {
    return request({
      url: 'appointments/uploadImage',
      method: 'POSt',
      data,
    })
  },
  //#region 时间段模板
  getAllTimeTemplate: () => {
    return request({
      url: 'appointments/allTimeTemplate',
      method: 'GET',
    })
  },
  getTimeTemplateBySearch: (data: any) => {
    return request({
      url: 'appointments/timeTemplateBySearch',
      method: 'POST',
      data,
    })
  },
  insertTimeTemplate: (data: any) => {
    return request({
      url: 'appointments/insertTimeTemplate',
      method: 'POST',
      data,
    })
  },
  updateTimeTemplate: (data: any) => {
    return request({
      url: 'appointments/updateTimeTemplate',
      method: 'POST',
      data,
    })
  },
  updateTimeTemplateStatus: (data: any) => {
    return request({
      url: 'appointments/updateTimeTemplateStatus',
      method: 'GET',
      params: data
    })
  },
  //#endregion
  getInputCode: (inputValue) => {
    return request({
      url: 'system/inputCode?inputValue=' + inputValue,
      method: 'GET',
    })
  },
  //#region 院区信息
  getAllHospitalareas: () => {
    return request({
      url: 'system/hospitalareas',
      method: 'GET',
    })
  },
  getHospitalareasBySearch: (data) => {
    return request({
      url: 'system/hospitalareasBySearch',
      method: 'POST',
      data,
    })
  },
  insertSysHospitalArea: (data) => {
    return request({
      url: 'system/insertSysHospitalArea',
      method: 'POST',
      data,
    })
  },
  updateSysHospitalAreaById: (data) => {
    return request({
      url: 'system/updateSysHospitalAreaById',
      method: 'POST',
      data,
    })
  },
  updateSysHospitalAreaStatus: (data) => {
    return request({
      url: 'system/updateSysHospitalAreaStatus',
      method: 'GET',
      params: data
    })
  },
  deleteSysHospitalAreaById: (id) => {
    return request({
      url: 'system/deleteSysHospitalAreaById/' + id,
      method: 'DELETE',
    })
  },
  //#endregion
  //#region 科室信息
  getAllDepartments: () => {
    return request({
      url: 'system/departments',
      method: 'GET',
    })
  },
  getDepartmentsBySearch: (data) => {
    return request({
      url: 'system/departmentsBySearch',
      method: 'POST',
      data,
    })
  },
  insertSysDepartments: (data) => {
    return request({
      url: 'system/insertSysDepartments',
      method: 'POST',
      data,
    })
  },
  updateSysDepartmentsById: (data) => {
    return request({
      url: 'system/updateSysDepartmentsById',
      method: 'POST',
      data,
    })
  },
  updateSysDepartmentsStatus: (data) => {
    return request({
      url: 'system/updateSysDepartmentsStatus',
      method: 'GET',
      params: data
    })
  },
  deleteSysDepartmentsById: (id) => {
    return request({
      url: 'system/deleteSysDepartmentsById/' + id,
      method: 'DELETE',
    })
  },
  //#endregion
  //#region 医生信息
  getAllDoctors: () => {
    return request({
      url: 'system/doctors',
      method: 'GET',
    })
  },
  getDoctorsBySearch: (data) => {
    return request({
      url: 'system/doctorsBySearch',
      method: 'POST',
      data,
    })
  },
  insertSysDoctors: (data) => {
    return request({
      url: 'system/insertSysDoctors',
      method: 'POST',
      data,
    })
  },
  updateSysDoctorsById: (data) => {
    return request({
      url: 'system/updateSysDoctorsById',
      method: 'POST',
      data,
    })
  },
  updateSysDoctorsStatus: (data) => {
    return request({
      url: 'system/updateSysDoctorsStatus',
      method: 'GET',
      params: data
    })
  },
  deleteSysDoctorsById: (id) => {
    return request({
      url: 'system/deleteSysDoctorsById/' + id,
      method: 'DELETE',
    })
  },
  //根据科室ID查询医生列表信息
  getDoctorsByDepartmentId: (departmentId) => {
    return request({
      url: 'system/doctorsByDepartmentId?departmentId='+departmentId,
      method: 'GET',
    })
  },
  //#endregion
}
