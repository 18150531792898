import sysRequest from '@/api/sys'
import md5 from 'md5'
import { UserInfo, StateData, User,StaticData } from '@/Interfaces/user/user'
import { SelectTypes } from '@/Interfaces/user/DataItem'
import Settings from '@/Interfaces/system/setting'
import customerRequest from '@/api/customer'
import { setItem, getItem, removeItem } from '@/utils/storege'
import { TOKEN,PREVILIGES,ORGINFO, USER_INFO,CATEGORY_DATA,SETTINGS,STYLE_DATA,SIZE_DATA,UNITS_DATA,USERINFO,PROVENCES } from '@/constants'
import store from '@/store'
import router from '@/router'
import {message} from 'ant-design-vue'
export default {
  namespaced: true,
  state: {
    token: getItem(TOKEN) || '',
    orgId:getItem("ORGID") || 0,
    userInfo: getItem(USER_INFO) || {},
    categoryData:getItem(CATEGORY_DATA) || [],
    styleData:getItem(STYLE_DATA) || [],
    sizeData:getItem(SIZE_DATA) || [],
    userData:getItem(USERINFO),
    provences:getItem(PROVENCES) || [],
    settings:getItem(SETTINGS) || [],
    previliges:getItem(PREVILIGES) || {},
    orgInfo:getItem(ORGINFO) || {},
    noteId:getItem("NOTEID"),
    levels:getItem("LEVELS"),
    loginCallback:null,
    isPad:false,
  },
  mutations: {
    setNoteId(state,noteId){
      state.noteId=noteId
      setItem("NOTEID", noteId)
    },
    setIsPad(state,isPad){
      state.isPad=isPad
    },
    setLevels(state,levels){
      state.levels=levels
      setItem("LEVELS", levels)
    },
    clearNoteId(state){
      state.noteId=null
      window.localStorage.removeItem("NOTEID")
    },
    setPreviliges(state:StateData,previliges:any){
      state.previliges=previliges
      setItem(PREVILIGES, previliges)
    },
    setOrgInfo(state:StateData,orgInfo:any){
      state.orgInfo=orgInfo
      setItem(ORGINFO, orgInfo)
    },
    setToken(state: StateData, token: string) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserInfo(state: StateData, userInfo: User) {
      state.userInfo = userInfo
      setItem(USER_INFO, userInfo)
    },
    setCategoryData(state: StateData, categorys: Array<StaticData>) {
      state.categoryData = categorys
      setItem(CATEGORY_DATA, categorys)
    },
    setStyleData(state: StateData, style: Array<StaticData>) {
      state.styleData = style
      setItem(STYLE_DATA, style)
    },
    setSizeData(state: StateData, size: Array<StaticData>) {
      state.sizeData = size
      setItem(SIZE_DATA, size)
    },
    setUnitsData(state: StateData, units: Array<StaticData>) {
      state.unitsData = units
      setItem(UNITS_DATA, units)
    },
    setProvence(state: StateData, provences: Array<SelectTypes>) {
      state.provences = provences
      setItem(PROVENCES, provences)
    },
    setSettings(state: StateData, settings: Array<Settings>) {
      state.settings = settings
      setItem(SETTINGS, settings)
    },
    setOrgId(state:StateData,orgId){
      state.orgId=orgId
      setItem("ORGID",orgId)
    }
  },
  actions: {
    async login(context: any, userinfo: UserInfo) {
      
      const { username, password } = userinfo
      const res: any = await sysRequest.login({
        username,
        password: password //md5(password)
      })
      const data = res.data
      if(!data.avatar){
        data.avatar = require('@/assets/images/avater.png')
      }
      if (data.url) {
        data.url = process.env.VUE_APP_BASE_API + data.url.slice(1)
      }
      userinfo.loginDate=new Date().getTime()
      if(userinfo.remebmer){
        setItem(USERINFO, userinfo)
      }else{
        localStorage.removeItem(USERINFO)
      }      
      store.commit('user/setToken', data.token)
      store.commit('user/setUserInfo', data)
      store.dispatch('user/getSettings')
      return data
    },
    async codeLogin(context: any, validation) {  
      try{    
      const res=await sysRequest.frontCodeLogin(validation.phone,validation.code,store.getters.orgId)
        const data = res.data
        if(!data.avatar){
          data.avatar = require('@/assets/images/avater.png')
        }
        
        if (data.url) {
          data.url = process.env.VUE_APP_BASE_API + data.url.slice(1)
        }
        validation.loginDate=new Date().getTime()
        if(validation.remebmer){
          setItem(USERINFO, validation)
        }else{
          localStorage.removeItem(USERINFO)
        }      
        store.commit('user/setToken', data.token)
        store.commit('user/setUserInfo', data)
        store.dispatch('user/getSettings')
        return data
      }catch(err){      
        message.error((err as any).message)
        return null
      }
    },
    
    async clientById(content:any,id:number){
      const res = await customerRequest.clientById(id)
      const data = res.data
      store.commit('user/setUserInfo', data)      
      if(!data.avatar){
        data.avatar = require('@/assets/images/avater.png')
      }
      if (data.url) {
        data.url = process.env.VUE_APP_BASE_API + data.url.slice(1)
      } 
      store.commit('user/setToken', data.token)
      store.commit('user/setUserInfo', data)
      return res
    },
    async getUserInfo(context: any) {
      const res = await sysRequest.getUserInfo()
      store.commit('user/setUserInfo', res)
      return res
    },
    async getCategoryData(context:any){
      const res = await sysRequest.getStaticData(1)  //type1服装类别，2服装风格，3商品单位
      store.commit('user/setCategoryData', res.data)

      return res.data
    },
    async getStyleData(context:any){
      const res = await sysRequest.getStaticData(2) //type1服装类别，2服装风格，3商品单位
      store.commit('user/setStyleData', res.data)
      return res.data
    },
    async getUnitData(context:any){
      const res = await sysRequest.getUnits() //type1服装类别，2服装风格，3商品单位
      store.commit('user/setUnitsData', res.data)
      return res.data
    },
    async getSizeData(context:any){
      const res = await sysRequest.getSize(0) //0全部，Orgid具体机构的
      store.commit('user/setSizeData', res.data)
      return res.data
    },
    async getSettings(context: any) {
      const res = await sysRequest.getSettings()
      const url= await sysRequest.getUrlHeader()
      const op:Array<Settings>=[]
      res.data.forEach(itm =>{          
        op.push(itm)
      });
      op.push({id:0,key:'imgurl',name:'OSS地址配置',desc:'',value:url.data,})
      const sett=op.reduce((calc,item)=>{
        if(!calc[item.key]){
          calc[item.key]=item
        }
        return calc
      },{})
      store.commit('user/setSettings', sett)
      return op
    },
    async getProvence(context: any) {
      const res = await sysRequest.getProvence()
      const op:Array<any>=[]
      res.data.forEach(itm =>{          
        op.push({label:itm.cityName,value:itm.cityCode,sp:itm.inputCode})
      });
      store.commit('user/setProvence', op)
      return op
    },
    // async getProvence(context: any) {
    //   const res = await sysRequest.getCities()
    //   const op:Array<SelectTypes>=[]
    //   res.data.forEach(itm =>{          
    //     op.push(itm)
    //   });
    //   store.commit('user/setProvence', op)
    //   return op
    // },
    logout() {
      store.commit('user/setToken', '')
      store.commit('user/setUserInfo', {})      
      removeItem(TOKEN)
    }
  }
}
//npm i --save-dev @types/md5
