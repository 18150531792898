
import { defineComponent, ref,onMounted,computed } from 'vue'
import { useStore } from 'vuex'
import sysRequest from '@/api/sys'
import { generateNewStyle } from '@/utils/theme'
import locale from '@/i18n/lang/zh'
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import enGB from 'ant-design-vue/lib/locale-provider/en_GB';
import store from '@/store'
export interface Operator {
  Name: string
  Age: number
}
export default defineComponent({
  setup() {
    const store = useStore()
    const person: Operator = {
      Name: 'jax ',
      Age: 34
    }
    const reconnectInterval = 5000;
    const socketRef = ref<WebSocket | null>(null);
    const initWebSocket = () => {
      const wsfapi = process.env.VUE_APP_BASE_WS + 'cus' + store.getters.userInfo.id;
      socketRef.value = new WebSocket(wsfapi);
      socketRef.value.addEventListener('open', () => {
        console.log('ws connected');
      });
      socketRef.value.addEventListener('message', (event) => {
        if(store.getters.onmessage){
          console.log('onmessage',store.getters.onmessage)
          store.getters.onmessage(event)
        }
      });

      // 关键：处理断开连接事件，触发重连
      socketRef.value.addEventListener('close', () => {
        console.log('WebSocket disconnected. Reconnecting in ' + reconnectInterval / 1000 + ' seconds...');
        setTimeout(initWebSocket, reconnectInterval); // 设置定时器进行重连
      });

      socketRef.value.addEventListener('error', (error) => {
        console.error('WebSocket error:', error);
      });
      store.state.app.socket=socketRef.value
    };
    const extractHostnameAndPort=(url)=> {  
      const urlObj = new URL(url);
      return urlObj.hostname; 
    }
    const lang=computed(() => {
      if(store.getters.language=='zh'){
        dayjs.locale('zh-cn');
        return zhCN
      }else{
        dayjs.locale('en-gb');
        return enGB
      }

    })
    const imgurl=computed(()=>{
      return store.getters.settings.imgurl.value
    })
    const setScale=()=>{  
      let screenWidth = window.innerWidth;  
      if (screenWidth <= 1024) {  
          let viewportMeta = document.querySelector('meta[name="viewport"]') as any;  
          if (!viewportMeta) {  
              viewportMeta = document.createElement('meta');  
              viewportMeta.name = "viewport";  
              document.head.appendChild(viewportMeta);  
          }  
          console.log(viewportMeta.content)
          viewportMeta.content = "width=device-width, initial-scale=0.9";  
      }  
    }
    
    onMounted(async () => {
      await store.dispatch('user/getSettings')
      if(!window.onresize){
        window.onresize=()=>{
          let width = window.innerWidth;  
          let height = window.innerHeight;  
          if(width<=1024 || width<=1180){
            store.commit('user/setIsPad',true)
          }else{
            store.commit('user/setIsPad',false)
          }
        }
      }
      window.setInterval(()=>{
        let width = window.innerWidth; 
        if(width<=1024 || width<=1180){
          store.commit('user/setIsPad',true)
        }else{
          store.commit('user/setIsPad',false)
        }
      },1000)
      const hostname=extractHostnameAndPort(window.location.href)
      const res=await sysRequest.getOrgId(hostname) //.then(res=>{
      if(res.data){
        store.commit('user/setOrgId', res.data.orgId)
        if(res.data.orgId!=1000){
          window.document.title="私人定制"
        }
      }
      console.log(store.getters.orgId)
      const fncsss= res.data.functions.reduce((acc,item)=>{
        if(!acc[item.functionName]){
          acc[item.functionName]=item
        }
        return acc
      },{})   
      store.commit('user/setPreviliges', fncsss)
      if(res.data.orgInfo.logoUrl) res.data.orgInfo.logoUrl=imgurl.value+'/'+res.data.orgInfo.logoUrl
      if(res.data.orgInfo.logoUrl1) res.data.orgInfo.logoUrl1=imgurl.value+'/'+res.data.orgInfo.logoUrl1
      if(res.data.orgInfo.settings) 
        res.data.orgInfo.settings=JSON.parse(res.data.orgInfo.settings)
      else{
        res.data.orgInfo.settings={alipay:false,offlinepay:false}
      }
      if(res.data.orgInfo.customerServiceUrl) res.data.orgInfo.customerServiceUrl=imgurl.value+'/'+res.data.orgInfo.customerServiceUrl
      store.commit('user/setOrgInfo', res.data.orgInfo)
      // })
      document.documentElement.style.setProperty('--activeColor', store.getters.theme.primaryColor);
      generateNewStyle(store.getters.theme)
      setScale()
      //initWebSocket()
    })
    // watchEffect(() => {
    //   if (socketRef.value && socketRef.value.readyState !== WebSocket.CLOSED) {
    //     socketRef.value.close(); // 先关闭现有连接
    //     initWebSocket(); // 重新初始化 WebSocket
    //   }
    // });
    return { person, locale:lang }
  }
})
