
import { defineComponent, onMounted, PropType, ref,watch } from 'vue'
import Level,{LevelAmount} from '@/Interfaces/customer/Level'
export default defineComponent({
  name:'LevelCard',
  props:{
    level:{
      type:Object as PropType<Level>,
      required:true
    },
    selected:{
      type:Boolean,
      default:false
    }
  },
  setup(props,{emit}) {
    const selectedIndex=ref(0)
    const ternClick=(tern,index)=>{
      selectedIndex.value=index
      props.level.selectedIndex=index
      emit('ternChange',tern)
    }
    const upgrade=()=>{
      emit('upgrade',props.level)
    }
    const selectedClick=()=>{
      props.level.selected=true
      emit('selectedChanged',props.level)
    }
    onMounted(()=>{
      props.level.showAll=false
    })
    
    return {
      props,selectedClick,ternClick,selectedIndex,upgrade
    }
  }
})
