
import MenuBar from './MenuBar.vue'
import store from '@/store'
import { defineComponent } from 'vue'
export default defineComponent({
  components: {
    'menu-bar': MenuBar,
  },
  setup(){
    const logoHeight = 40
    return {
      store,logoHeight
    }
    
  }
})

