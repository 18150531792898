import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08122c92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"margin":"10px","height":"30px"} }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_popover = _resolveComponent("a-popover")!

  return (_openBlock(), _createBlock(_component_a_popover, { title: "选择主题颜色" }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.predefineColors, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "color-block",
            style: _normalizeStyle([{"width":"20px","height":"20px","float":"left","margin":"5px 5px","cursor":"pointer"}, { background: `${item}` }]),
            onClick: ($event: any) => (_ctx.selectColor(item)),
            key: index
          }, null, 12, _hoisted_2))
        }), 128))
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "theme-wrapper",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showModal && _ctx.showModal(...args)))
      }, [
        _createVNode(_component_svg_icon, {
          class: "svgi",
          icon: "change-theme",
          style: {"font-size":"24px","margin-right":"20px"}
        })
      ])
    ]),
    _: 1
  }))
}