import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['app-main',(_ctx.path==='/main/index' && _ctx.scrolltop<50)?'main-background':''])
  }, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component, route }) => [
        (route.path.substr(0,15)=='/main/cuscenter')
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              key: route.path
            }))
          : (_openBlock(), _createBlock(_Transition, {
              key: 1,
              name: "fade",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  key: route.path
                }))
              ]),
              _: 2
            }, 1024))
      ]),
      _: 1
    })
  ], 2))
}