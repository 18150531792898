import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b51ae00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "c-tabs" }
const _hoisted_2 = {
  class: "c-tabs-nav-wrap",
  ref: "navWrap"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "tabs-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "c-tabs-inv-bar",
        style: _normalizeStyle(_ctx.barStyle)
      }, null, 4),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navList, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["c-tab-nav", { 'active-c-tab-nav': _ctx.activeKey == item.name }]),
          key: item.name,
          onClick: ($event: any) => (_ctx.onTabClick(item))
        }, _toDisplayString(item.title), 11, _hoisted_3))
      }), 128))
    ], 512),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}