
import store from '@/store'
import { computed, defineComponent, ref } from 'vue'
export default defineComponent({
  setup(){
    const iconclick = () => {
      //sideBarWidth.value=store.getters.sideBarOpened?210:80
      store.commit('app/openSidebar')
      
    }
    const icon = computed(() => {
      return store.getters.sideBarOpened ? 'hamburger-opened' : 'hamburger-closed'
    })
    return {
      iconclick,icon
    }
  }
})

