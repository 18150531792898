import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb81fda2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fixed-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_app_main = _resolveComponent("app-main")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['app-wrapper',(_ctx.path==='/main/index' && _ctx.scrolltop<50)?'app-wrapper-background':''])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Navbar)
    ]),
    _createVNode(_component_app_main)
  ], 2))
}