/**
 * token 存储的KEY
 */
export const TOKEN = 'fronttoken'
export const ORGINFO='orgInfo'
export const PREVILIGES='previliges'
export const USER_INFO = 'frontuserinfo'
export const CATEGORY_DATA = 'categoryData' //服装分类
export const STYLE_DATA = 'styleData' //服装风格
export const SIZE_DATA = 'sizeData' //服装尺码
export const UNITS_DATA = 'unitsdata' //服装尺码
export const USERINFO = 'userdata' //服装尺码
export const PROVENCES='provences' 
export const SETTINGS='settings' 
/**
 * TOKEN 有效期时长(毫秒数)
 */
export const TOKEN_VALIDAE_TIME = 48 * 3600 * 1000
/**
 * 登录时的token值的KEY
 */
export const LOGIN_STAMP = 'login_token'
/**
 * 多语言
 */
export const LANG = 'lang'
export const THEME = 'theme'
export const DEFAULT_THEME = {
  primaryColor: '#1890ff',
  linkColor: '#40a9ff'
}

export const TAGS_VIEW = 'tagsview'

//message 停留时间
export const MESSAGE_TIMEOUT = 2000;
export const LEFT_SIDE_MAX_WIDTH= 250
export const LEFT_SIDE_MIN_WIDTH= 80

