
import { useRouter, useRoute } from 'vue-router'
import { defineComponent, ref,reactive,toRefs,watch, watchEffect } from 'vue'
import { getMenus } from '@/utils/route'
import { generateTitle } from '@/utils/lang'
import MenuItem from '@/Interfaces/user/MenuItem'
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  MailOutlined,
  DesktopOutlined,
  InboxOutlined,
  AppstoreOutlined,
} from '@ant-design/icons-vue';
import store from '@/store'
import components from '@/components/index';

export default defineComponent({
  setup() {
    const router = useRouter()
    const route = useRoute()
    const routes = getMenus(router.getRoutes())
    const openKeys = ref<string[]>([])
      const state = reactive({
      collapsed: false,
      selectedKeys: ['/platform/platforminfo'],
      openKeys: ['/platform/platforminfo'],
      preOpenKeys: ['sub1'],
    });
    const menuClick = (menu:MenuItem,key:string) => {
      if (!menu.children) {
        router.push(menu.path)
      }
    }
    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
        console.log(val)
      },
    );
    const menus = ref<MenuItem[]>([{title:'系统图标',enabled:false,path:'/platform/icons',icon:'personnel-manage'}])
    // console.log(route.fullPath, 'route')
    const openMenu = (menu) => {
      let index = openKeys.value.indexOf(menu.path)
      if (index != -1) {
        openKeys.value.splice(index, 1)
      } else {
        openKeys.value = [menu.path]

      }
      //console.log(menu)
    }
    // watchEffect(() => {
    //   state.openKeys = store.getters.sideBarOpened ?  state.preOpenKeys:[];
    // })
    const expaned = (menu) => {
      menu.expaned=!menu.expaned;
    }
    return {
      menuClick, ...toRefs(state),openMenu, store, generateTitle, openKeys, routes, route,menus,expaned
    }
  },
  components:{
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    PieChartOutlined,
    MailOutlined,
    DesktopOutlined,
    InboxOutlined,
    AppstoreOutlined,
  }
})

