
import { defineComponent, computed,PropType } from 'vue'
import CardData from "@/Interfaces/user/CardData"
import { message } from 'ant-design-vue'
import cusRequest from '@/api/customer'
import store from '@/store'
export default defineComponent({
  name:"ClothCard",
  props:{
    width:{
      type:Number,
      default:230
    },
    height:{
      type:Number,
      default:400
    },
    value:{
      type:Object as PropType<CardData>,
      required:true
    }
  },
  setup(props,{emit}) {
    const previliges=computed(()=>{
      return store.getters.previliges
    })
    const orgId=computed(()=>{
      return store.getters.orgId
    })
    const addFavorite=()=>{
      const params={
        orgId:store.getters.orgId,
        customerId:store.getters.userInfo.id,
        productId:props.value.id,
        productType:props.value.productType
      }
      if(!props.value.favorite || props.value.favorite==0){
        cusRequest.addFavorite(params).then(res=>{
          props.value.favorite=res.data
          message.success({content:'收藏成功！',style:{marginTop:'60px'}})
        })
      }else{
        cusRequest.delFavorite(props.value.favorite).then(res=>{
          props.value.favorite=0
          message.success({content:'取消收藏成功！',style:{marginTop:'60px'}})
      })
      }
      
    }
    const cardClick=()=>{
      emit("cardClick",props.value)
    }
    return {
      cardClick,addFavorite,previliges,orgId
    }
  }
})
