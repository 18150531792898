export const setItem = (key: string, value: any): void => {
  let str = ''
  if (typeof value === 'object') {
    str = JSON.stringify(value)
  } else {
    str = value
  }
  window.localStorage.setItem(key, str)
}

export const getItem = (key: string): any => {
  const data = window.localStorage.getItem(key)
  try {
    if (data) {
      return JSON.parse(data)
    }
    return null
  } catch (err) {
    return data
  }
}

export const removeItem = (key: string): void => {
  window.localStorage.removeItem(key)
}
