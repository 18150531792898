import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-122a8bbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "breadcrumb-container" }
const _hoisted_2 = { class: "back-icon" }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_breadcrumb_item = _resolveComponent("a-breadcrumb-item")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_svg_icon, {
        onClick: _ctx.goback,
        icon: "prev"
      }, null, 8, ["onClick"])
    ]),
    _createVNode(_component_a_breadcrumb, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadPath, (path) => {
          return (_openBlock(), _createBlock(_component_a_breadcrumb_item, {
            key: path.path
          }, {
            default: _withCtx(() => [
              (path.path !== _ctx.currentPath)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: path.path
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.generateTitle(path.meta.title)), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.generateTitle(path.meta.title)), 1))
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}