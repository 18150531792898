import { SelectOptions } from '@/Interfaces/invoker/jsObject';



/**
 * 日期格式转换
 * @param fmt
 * @param timeStamp 
 * @returns
 */
export function formatDate(fmt: string, timeStamp?: number | string): string {
  let ret
  let date
  if (typeof timeStamp === 'string') {
    date = new Date(parseInt(timeStamp))
  } else if (typeof timeStamp === 'number') {
    date = new Date(timeStamp)
  } 
  else {
    return ''
  }
  if (date.getFullYear().toString() == 'NaN')
    return ''
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "M+": (date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1).toString()) : (date.getMonth() + 1).toString(), // 月
    "D+": date.getDate() < 10 ? '0' + date.getDate().toString() : date.getDate().toString(),      // 日
    "H+": date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours().toString(),      // 时
    "m+": date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes().toString(),  // 分
    "s+": date.getSeconds().toString() // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  }
  for (const k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt)
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    }
  }
  return fmt
}
export function copyTextToClipboard (text){  
  const textArea = document.createElement("textarea");  
  textArea.value = text;  
  document.body.appendChild(textArea);  
  textArea.focus();  
  textArea.select();  
  try {  
    const successful = document.execCommand('copy');  
    document.body.removeChild(textArea);  
    return successful
  } catch (err) {  
    document.body.removeChild(textArea);  
    return false
  }
}
export function strToDate(dateStr: string): Date {
  const year = dateStr.substring(0, 4);  
  const month = dateStr.substring(4, 6);  
  const day = dateStr.substring(6, 8);  
  const formattedDate = year + "-" + month + "-" + day;  
  return new Date(formattedDate);  
}
export function guid():string {  
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {  
      const r = Math.random() * 16 | 0
      const v = c === 'x' ? r : (r & 0x3 | 0x8);  
      return v.toString(16);  
  });  
}  

export function codeToText(ds: Array<SelectOptions>, code: string): string  {
  if(!ds) return ''
  const res = ds.filter(itm => itm.value == code)
  if (res && res.length > 0) {
    return res[0].label
  } else {
    return ''
  }
}
export function _debounce(fn: (...args) => void, delay = 500, immediate = false) {
  //定义一个定时器
  let timer
  let isInvoke = false

  return function (this: any, ...args) {
    if (timer) clearTimeout(timer)
    //第一次调用立即执行
    if (immediate && !isInvoke) {
      fn.apply(this, args);
      isInvoke = true
    } else {
      timer = setTimeout(() => {
        fn.apply(this, args)
        isInvoke = false
      }, delay)
    }
  }
}
export function _throttle(func: (...args) => void, delay = 500) {
  let old = 0
  return function (this: any, ...args) {
    const now = new Date().getTime()
    if (now - old > delay) {
      func.apply(this, args)
      old = now
    }
  }
}